import React, { useEffect, useState, useCallback } from "react"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import styles from "./../ClientResponse/ClientResponse.module.scss"
import FileItemDocs from "../../../components/FileItemDocs/FileItemDocs"
import { i_RequestedDocument_2 } from "../../../lib/services"
import _ from "lodash"
const RenderCard = (props: any) => {
  const {
    documentType,
    item,
    selectAllHandler,
    checkDownloadHandler,
    onFileUploaderModalOpen,
    refreshRequestedDocuments,
    clientId,
    index,
    checkSwitch,
  } = props

  const indetSetter = React.useCallback(
    (el: any) => {
      if (el) {
        el.indeterminate = checkSwitch[index] === "semi"
      }
    },
    [checkSwitch[index]]
  )

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={
          <ExpandMoreIcon style={{ fill: "var(--primary-color)", fontSize: 30 }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={styles.heading}>{documentType}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={styles.fileItemContainer}>
          <div className={styles.selectAllBtnContainer}>
            <input
              type="checkbox"
              checked={checkSwitch[index]}
              className={styles.checkBox}
              onChange={() => selectAllHandler(documentType, index)}
              ref={indetSetter}
            />
            <p
              onClick={() => selectAllHandler(documentType, index)}
              className={styles.selectAll}
            >
              Select All
            </p>
          </div>
          <hr className={styles.divider} />
          {item &&
            item.map((document: i_RequestedDocument_2) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FileItemDocs
                  checkDownloadHandler={checkDownloadHandler}
                  item={document}
                  onFileUploaderModalOpen={onFileUploaderModalOpen}
                  refreshRequestedDocuments={refreshRequestedDocuments}
                  clientId={clientId}
                  isFromDocument={true}
                  folder="Mortgages"
                />
              </Grid>
            ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default RenderCard
