exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3x5Wcw8pLZXIq1Y6NitXNW {\n  padding: 10px 10px 5px;\n  margin-bottom: 5px; }\n\n._1ebmCoJOIa4SgZgS_zgZYe {\n  margin: 2px 0px 0px 8px; }\n\n._2dChSudMVmUYMu-Afi42jU {\n  color: var(--primary-color) !important;\n  font-size: 20px !important; }\n\n.IkeFcQg5gbmePifRc54yS {\n  display: flex;\n  justify-content: flex-end; }\n  .IkeFcQg5gbmePifRc54yS a {\n    margin: 0 10px;\n    position: relative;\n    top: 8px; }\n    .IkeFcQg5gbmePifRc54yS a:hover svg {\n      fill: var(--error-color); }\n\n.v4kjvFOilZfRMVV1A1P9A {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._rFzSOGpI3Bjsmytwyh-e {\n  color: var(--error-color); }\n\n._3QA54BJDy27h5PkynaDNTi {\n  color: var(--primary-color); }\n\n._1ZhXXpUTv2yUTa2ko9AiU6 {\n  width: 35em; }\n  ._1ZhXXpUTv2yUTa2ko9AiU6._3j3ypGOt_Y00O-jiDaZTpF {\n    border: 1px solid var(--error-color); }\n  ._1ZhXXpUTv2yUTa2ko9AiU6 > div {\n    background: #d5d5d5;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2px !important; }\n    ._1ZhXXpUTv2yUTa2ko9AiU6 > div > button {\n      padding: 0; }\n    ._1ZhXXpUTv2yUTa2ko9AiU6 > div > input {\n      padding: 2px !important;\n      font-size: 14px; }\n    ._1ZhXXpUTv2yUTa2ko9AiU6 > div > textarea {\n      padding-top: 4px !important;\n      padding-bottom: 4px !important;\n      padding-left: 2px !important;\n      padding-right: 2px !important;\n      font-size: 14px; }\n\n._2_F8n2p1vZDtvyQx0aWafT {\n  margin: 0 10px 2px 10px !important;\n  width: 15px !important;\n  height: 15px !important; }\n", ""]);
// Exports
exports.locals = {
	"files": "_3x5Wcw8pLZXIq1Y6NitXNW",
	"fileName": "_1ebmCoJOIa4SgZgS_zgZYe",
	"icons": "_2dChSudMVmUYMu-Afi42jU",
	"iconHolder": "IkeFcQg5gbmePifRc54yS",
	"spinner": "v4kjvFOilZfRMVV1A1P9A",
	"textRed": "_rFzSOGpI3Bjsmytwyh-e",
	"textGreen": "_3QA54BJDy27h5PkynaDNTi",
	"inputWrapper": "_1ZhXXpUTv2yUTa2ko9AiU6",
	"duplicateDoc": "_3j3ypGOt_Y00O-jiDaZTpF",
	"checkBox": "_2_F8n2p1vZDtvyQx0aWafT"
};