import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

export const FontFamilyPoppins = '"Poppins", sans-serif';

export const FontSizeBody2 = "0.875em";

export const ColorPrimary = "var(--primary-color)";

export const ColorPrimaryDark = "var(--primary-color)";

export const ColorPrimaryLight = "var(--primary-color)";

export const ColorGrey = "var(--grey-color-1)";

export const ColorLightGrey = "var(--grey-color-3)";

export const ColorLighterGrey = "var(--grey-color-5)";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#54186b",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
        textTransform: "none",
        fontSize: "1em",
        marginLeft: "0.25em",
        marginRight: "0.25em",
        padding: "0.25em 1em",
      },
      containedSizeLarge: {
        fontSize: "1.25em",
      },
    },
    MuiIconButton: {
      colorPrimary: {
        fontFamily: FontFamilyPoppins,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1em",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1em",
      },
    },
    MuiSelect: {
      root: {
        padding: "0.5em 1em",
        height: "1em",
      },
      icon: {
        top: "0.25em",
        height: "1.5em",
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "var(--h1-size)",
        lineHeight: "var(--h1-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      h2: {
        fontSize: "var(--h2-size)",
        lineHeight: "var(--h2-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      h3: {
        fontSize: "var(--h3-size)",
        lineHeight: "var(--h3-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      h4: {
        fontSize: "var(--h4-size)",
        lineHeight: "var(--h4-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      h5: {
        fontSize: "var(--h5-size)",
        lineHeight: "var(--h5-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      h6: {
        fontSize: "var(--h6-size)",
        lineHeight: "var(--h6-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      body1: {
        fontSize: "var(--body-normal-text-size)",
        lineHeight: "var(--body-normal-text-line-height)",
        fontFamily: FontFamilyPoppins,
      },
      body2: {
        fontSize: "var(--body-small-text-size)",
        lineHeight: "var(--body-small-text-line-height)",
        fontFamily: FontFamilyPoppins,
      },
    },
  },
});

export default theme;

export const useThemeStyles = makeStyles({
  colorPrimaryLight: {
    color: ColorPrimaryLight,
  },
  iconContainedPrimary: {
    backgroundColor: ColorPrimary,
    color: "white",
    "&:hover": {
      backgroundColor: "var(--primary-color)",
      color: "white",
    },
  },
  mb2: {
    marginBottom: "2em",
  },
  articleTitle: {
    textAlign: "center",
    color: ColorPrimaryDark,
  },
});
