import React, { useState, useEffect } from "react";
import {
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Switch from "@material-ui/core/Switch";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import styles from "./DataTable.module.scss";
import { formatMoney } from "./../../scenes/MBWorkbench/PreliminaryAssessment/AssessmentCalculator/calculations"; // need to transfer this to clobal utils

interface i_DataTable {
  handleUpdate: Function;
  table: String;
  columns: Array<String>;
  rows?: any;
  previous_income?: Array<Number>;
  handleAdd?: any;
  handleDelete?: any;
  overAllTotal?: any;
  getOverAllTotal?: any;
  funcGetTotal?: any;
  hideTotal?: Boolean;
}

const OrangeSwitch = withStyles({
  root: {
    padding: "4px",
  },
  switchBase: {
    color: "white",
    "&$checked": {
      color: "var(--primary-color)",
    },
    "&$checked + $track": {
      backgroundColor: "var(--primary-color)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const calendarTheme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

const DataTable = (props: i_DataTable) => {
  const { rows, handleDelete, overAllTotal, funcGetTotal, hideTotal } = props;
  const [table] = useState(props.table);
  const [columns] = useState(props.columns);
  const [ftotal, setFTotal] = useState<any>();

  useEffect(() => {
    funcGetTotal && setFTotal(funcGetTotal(rows).total);
  }, [rows]);

  const modifyRow = (item: any, id: Number, value: any) => {
    props.handleUpdate(table, item, id, value);
    funcGetTotal && setFTotal(funcGetTotal(rows).total);
  };

  const renderCell = (item: any, index: any) => {
    switch (item.type) {
      case "spacer":
        return <span>&nbsp;</span>;
      case "select":
        return (
          <Select
            native
            className={styles.selectInput}
            value={item.val}
            onChange={(e: React.ChangeEvent<{ value: any }>) =>
              modifyRow(item, index, e.target.value)
            }
            style={{ borderColor: item.borderColor || "transparent" }}
          >
            {item.options.map((option: any, index: any) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Select>
        );
      case "currency":
        if (item.isReadOnly)
          return <span className={styles.fieldRead}>${item.val}</span>;
        else
          return (
            <TextField
              size="small"
              variant="outlined"
              value={item.val}
              classes={{ root: styles.currencyInput }}
              onBlur={(e: React.ChangeEvent<{ value: any }>) =>
                modifyRow(
                  item,
                  index,
                  parseFloat(e.target.value.replace(/,/g, ""))
                )
              }
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                modifyRow(item, index, e.target.value)
              }
              inputProps={{
                maxlength: 7,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          );
      case "date":
        return (
          <ThemeProvider theme={calendarTheme}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale="au"
            >
              <KeyboardDatePicker
                autoOk
                variant="inline"
                format="DD-MM-YYYY"
                InputProps={{ className: styles.dateInput }}
                value={moment(new Date(item.val)).format("YYYY-MM-DD")}
                onChange={(date) => modifyRow(item, index, date?.format())}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        );
      case "field":
        if (item.isReadOnly) {
          if (item.key === "address" && Array.isArray(item.val)) {
            return <span>{item.val.join(", ")}</span>;
          } else {
            return <span>{item.val}</span>;
          }
        } else
          return (
            <TextField
              size="small"
              variant="outlined"
              value={item.val}
              classes={{ root: styles.fieldInput }}
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                modifyRow(item, index, e.target.value)
              }
            />
          );
      case "showMoreDetails":
        return (
          <span
            aria-label="expand row"
            onClick={() => {
              return modifyRow(item, index, !item.val);
            }}
          >
            {item.val ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </span>
        );
      case "percentage":
        if (item.isReadOnly) return <span>{item.val}%</span>;
        else
          return (
            <OutlinedInput
              margin="dense"
              classes={{ root: styles.currencyInput }}
              value={item.val}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                modifyRow(item, index, e.target.value)
              }
            />
          );
      case "switch":
        return (
          <OrangeSwitch
            size="small"
            checked={item.val}
            onChange={() => {
              if (item.key === "isRefi") {
                _.mapValues(rows[0], function (row) {
                  if (row.key === "repayment" || row.key === "balance") {
                    row.isComputed = !item.val;
                    row.isForTotal = !item.val;
                  }
                });
              }
              modifyRow(item, index, !item.val);
            }}
          />
        );
      case "moredetails":
        return (
          <>
            <Table
              size="small"
              aria-label="purchases"
              className={styles.smallTable}
            >
              <TableBody>
                {item.details &&
                  item.details.map((detail: any) => (
                    <TableRow
                      key={detail.flexType}
                      className={styles.modalTableRow}
                    >
                      <TableCell>{detail.desc}</TableCell>
                      <TableCell className={styles.modalTableCell}>
                        <span>
                          $
                          {!_.isNil(detail.monthlyAmount)
                            ? formatMoney(detail.monthlyAmount)
                            : 0}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </>
        );
      default:
        return "";
    }
  };

  const IS_OPEN_INDEX = 0;
  return (
    <TableContainer className={styles.dataTableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {table === "homeLoans" &&
              columns
                .slice(0, 10)
                .map((item: any, index: any) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
            {table !== "homeLoans" &&
              columns.map((item: any, index: any) =>
                item === "Add" ? (
                  <TableCell key={index}>
                    <AddCircleIcon
                      fontSize="default"
                      className={styles.headerAddIcon}
                      onClick={() => props.handleAdd && props.handleAdd()}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={index}>{item}</TableCell>
                )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(!rows || rows.length === 0) && (
            <TableRow>
              <TableCell colSpan={20}>
                <p style={{ textAlign: "left" }}>
                  Press the (+) button above to add data here.
                </p>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row: any, i: any) => (
            <>
              <TableRow key={`row-${i}`}>
                {table === "homeLoans"
                  ? row.slice(0, 10).map((item: any, index: any) =>
                      item.type == "delete" ? (
                        <TableCell key={index}>
                          <DeleteIcon
                            classes={{ root: styles.deleteIcon }}
                            fontSize="large"
                            onClick={() => handleDelete(i)}
                          />
                        </TableCell>
                      ) : (
                        <TableCell key={index}>{renderCell(item, i)}</TableCell>
                      )
                    )
                  : row
                      ?.filter((item: any) => item.type !== "hidden")
                      .map((item: any, index: any) => {
                        switch (item.type) {
                          case "delete":
                            return (
                              <TableCell key={index}>
                                <DeleteIcon
                                  classes={{ root: styles.deleteIcon }}
                                  fontSize="large"
                                  onClick={() => handleDelete(i)}
                                />
                              </TableCell>
                            );
                          case "moredetails":
                            return null;
                          default:
                            return (
                              <TableCell
                                key={index}
                                colSpan={item.colSpan || 0}
                                style={{ color: item.color || "inherit" }}
                              >
                                {renderCell(item, i)}
                              </TableCell>
                            );
                        }
                      })}
              </TableRow>
              {row
                ?.filter((item: any) => item.type !== "hidden")
                .map(
                  (item: any, index: any) =>
                    item.type === "moredetails" && (
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={row[IS_OPEN_INDEX].val}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>{renderCell(item, i)}</Box>
                        </Collapse>
                      </TableCell>
                    )
                )}
            </>
          ))}
        </TableBody>
        {table === "homeLoans" && (
          <TableHead>
            <TableRow>
              <TableRow></TableRow>
              {columns
                .slice(10, 19)
                .map((item: any, index: any) =>
                  item === "Add" ? (
                    <AddCircleIcon
                      fontSize="default"
                      className={styles.headerAddIcon}
                      onClick={() => props.handleAdd && props.handleAdd()}
                    />
                  ) : (
                    <TableCell key={index}>{item}</TableCell>
                  )
                )}
            </TableRow>
          </TableHead>
        )}
        {table === "homeLoans" && (
          <TableBody>
            {rows?.map((row: any, i: any) => (
              <TableRow key={`row-${i}`}>
                <TableRow></TableRow>
                {row
                  .slice(10, 19)
                  .map((item: any) =>
                    item.type == "delete" ? (
                      <DeleteIcon
                        classes={{ root: styles.deleteIcon }}
                        fontSize="large"
                        onClick={() => handleDelete(i)}
                      />
                    ) : (
                      <TableCell>{renderCell(item, i)}</TableCell>
                    )
                  )}
              </TableRow>
            ))}
          </TableBody>
        )}
        {!hideTotal && (
          <TableFooter>
            {ftotal && ftotal.length > 0 && (
              <TableRow>
                {rows[0]
                  ?.filter((item: any) => item.type !== "hidden")
                  .map((item: any, index: any) =>
                    index === 0 ? (
                      <TableCell style={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                    ) : (
                      <TableCell>
                        {ftotal[index] && (
                          <span className={styles.fieldRead}>
                            ${formatMoney(ftotal[index])}
                          </span>
                        )}
                      </TableCell>
                    )
                  )}
              </TableRow>
            )}
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default DataTable;
