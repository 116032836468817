import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye"
import GetAppIcon from "@material-ui/icons/GetApp"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import moment from "moment"
import { FadeLoader } from "react-spinners"
import { deleteFile } from "../../scenes/DocumentCollection/Documents/Documents.controller"
import { DownloadFile, DeleteFile, ViewFile } from "../../lib/services"
import styles from "./FileItemDocs.module.scss"
interface i_FileItem {
  item: any
  onFileUploaderModalOpen: any
  refreshRequestedDocuments: any
  clientId: any
  isFromDocument: boolean
  folder?: string
  checkDownloadHandler?: Function
}
const FileItemDocs: React.FC<i_FileItem> = ({
  item,
  onFileUploaderModalOpen,
  refreshRequestedDocuments,
  clientId,
  isFromDocument,
  folder,
  checkDownloadHandler,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const DeleteFileHandler = async (props: any) => {
    setIsLoading(true)
    if (isFromDocument) {
      await deleteFile({ uid: props.uid, fileId: props.id })
    } else {
      await DeleteFile(props.fileName, clientId)
    }
    await refreshRequestedDocuments()
    setTimeout(function() {
      setIsLoading(false)
    }, 500)
  }

  return (
    <>
      {isLoading && (
        <div className={[styles.spinner, "loading-spinner"].join(" ")}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color={"#999"}
            loading={isLoading}
          />
        </div>
      )}
      <Paper className={styles.files}>
        <Grid container>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <input
                className={styles.checkBox}
                type="checkbox"
                checked={item.checked}
                onChange={() => {
                  if (checkDownloadHandler !== undefined) {
                    checkDownloadHandler(item)
                  }
                }}
              />
              <InsertDriveFileIcon className={styles.icons} />{" "}
              <strong
                className={styles.fileName}
                style={{ marginRight: "20px" }}
              >
                {item.label || item.fileLabel}
              </strong>
              <small className={styles.fileName}>
                Created At :{" "}
                {moment
                  .utc(item.createdAt || item.createdAt)
                  .format("YYYY-MM-DD")}
              </small>
              <small className={styles.fileName}>
                {item.updatedAt &&
                  [
                    " | ",
                    "Modified At : ",
                    moment.utc(item.updatedAt).format("YYYY-MM-DD"),
                  ].join("")}
                {item.updatedAt &&
                  [
                    " | ",
                    "Modified At : ",
                    moment.utc(item.updatedAt).format("YYYY-MM-DD"),
                  ].join("")}
              </small>
            </div>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <div className={styles.iconHolder}>
              {" "}
              <a
                href="#"
                onClick={() =>
                  ViewFile(
                    item.file_name || item.AWSFileKey.split("/").pop(),
                    clientId,
                    folder
                  )
                }
              >
                {" "}
                {/* eslint-disable-line */}{" "}
                <RemoveRedEyeIcon className={styles.icons} />
              </a>
              <a
                href="#"
                onClick={() =>
                  DownloadFile(
                    item.file_name || item.AWSFileKey.split("/").pop(),
                    item.label || item.fileLabel,
                    clientId,
                    folder
                  )
                }
              >
                {" "}
                {/* eslint-disable-line */}{" "}
                <GetAppIcon className={styles.icons} />
              </a>
              <a
                href="#"
                onClick={onFileUploaderModalOpen(
                  item._id,
                  true,
                  item.documentType || (item.dcid && item.dcid.documentType),
                  item.fileLabel || item.label,
                  item._id || null
                )}
              >
                {" "}
                {/* eslint-disable-line */}{" "}
                <EditIcon className={styles.icons} />
              </a>
              <a
                href="#"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you want to proceed delete?")
                  ) {
                    DeleteFileHandler({
                      fileName: item.file_name,
                      id: item._id,
                      uid: clientId,
                    })
                  }
                }}
              >
                {" "}
                {/* eslint-disable-line */}{" "}
                <DeleteIcon className={styles.icons} />
              </a>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
export default FileItemDocs
