exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2fHJlNPS0gH31UCf-o16k2 {\n  border-radius: 4px;\n  padding: 8px;\n  width: 100%;\n  border: 1px solid #999999; }\n  ._2fHJlNPS0gH31UCf-o16k2[readonly] {\n    background: #ccc; }\n\n._3t_gH6znp8n6aSQCkSESf0 {\n  border-radius: 4px;\n  padding: 8px;\n  width: 100%;\n  border: 1px solid #999999;\n  cursor: pointer;\n  background: #efefef; }\n  ._3t_gH6znp8n6aSQCkSESf0[readonly] {\n    background: #ccc; }\n  ._3t_gH6znp8n6aSQCkSESf0:hover {\n    background-color: lightblue; }\n\n._1lRzL_7q9l2BzmItllk0t4 {\n  border-radius: 4px;\n  padding: 8px;\n  width: 100%;\n  border: 1px solid #999999;\n  cursor: pointer;\n  background: pink; }\n  ._1lRzL_7q9l2BzmItllk0t4[readonly] {\n    background: #ccc; }\n  ._1lRzL_7q9l2BzmItllk0t4:hover {\n    background-color: lightblue; }\n\n._3LEKMIe7UieiRRNKuyiVNf {\n  background: var(--primary-color-scale-75) !important;\n  color: var(--white-color) !important;\n  float: right !important; }\n  ._3LEKMIe7UieiRRNKuyiVNf:hover {\n    background: var(--primary-color) !important; }\n\n._2M40ztn3qGK41PQEIF7i9G {\n  background: var(--secondary-color-75) !important;\n  color: var(--white-color) !important;\n  float: right !important;\n  margin-right: 5px !important; }\n  ._2M40ztn3qGK41PQEIF7i9G:hover {\n    background: var(--secondary-color) !important; }\n\n._7W1WjmFjutF-tYmeAH46x {\n  font-size: 12px; }\n\n._1yQ00zajPeKEX8QFxbQWUl {\n  font-size: 14px !important; }\n\n._1Axi6V0PsiGfV539WLoEQ6 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n", ""]);
// Exports
exports.locals = {
	"fileName": "_2fHJlNPS0gH31UCf-o16k2",
	"fileNameSelected": "_3t_gH6znp8n6aSQCkSESf0",
	"inputError": "_1lRzL_7q9l2BzmItllk0t4",
	"primaryButton": "_3LEKMIe7UieiRRNKuyiVNf",
	"secondaryButton": "_2M40ztn3qGK41PQEIF7i9G",
	"tipText2": "_7W1WjmFjutF-tYmeAH46x",
	"alertMessage": "_1yQ00zajPeKEX8QFxbQWUl",
	"spinner": "_1Axi6V0PsiGfV539WLoEQ6"
};