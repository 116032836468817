exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n._1X7yLAQRwmtcuWAFVhL0nJ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._3FAHDLNCs1ScpyZlX6HN6W {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 1em;\n  cursor: pointer; }\n\n._3PPckw6lKS84ZcUCtYWG0i {\n  width: 20px;\n  margin-right: 1em;\n  margin: 0 1em 0 1em;\n  cursor: pointer; }\n\n.BkgPPXuPL0tAJouY4UBiF {\n  margin: 1em;\n  background-color: #ffd2d2;\n  padding: 1em; }\n  .BkgPPXuPL0tAJouY4UBiF p {\n    font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace;\n    color: var(--error-color);\n    margin: 0; }\n\n.PCXQ_zrWAifxBxvNsNVIP {\n  font-size: 14px !important;\n  color: #333333 !important;\n  line-height: 18px !important;\n  margin-bottom: 0px !important;\n  cursor: pointer;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace; }\n\n._1u-WH7hn9msmDWt68t4Qe2 {\n  font-size: 30px;\n  font-weight: 700;\n  position: relative;\n  float: right;\n  right: 10px;\n  color: var(--error-color); }\n  ._1u-WH7hn9msmDWt68t4Qe2:hover {\n    cursor: pointer; }\n\n._3IDzIfcTkFAKWqJg5PS4O3 {\n  margin: 1em;\n  background-color: #dff0d8;\n  padding: 1em; }\n  ._3IDzIfcTkFAKWqJg5PS4O3 p {\n    font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace;\n    color: var(--success-color);\n    margin: 0; }\n  ._3IDzIfcTkFAKWqJg5PS4O3 .cw5bEryIoz8E6UUzYUZj9 {\n    color: var(--success-color);\n    font-weight: 600;\n    text-transform: uppercase; }\n\n._1X7yLAQRwmtcuWAFVhL0nJ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "_1X7yLAQRwmtcuWAFVhL0nJ",
	"downloadAllContainer": "_3FAHDLNCs1ScpyZlX6HN6W",
	"downloadIcon": "_3PPckw6lKS84ZcUCtYWG0i",
	"errorNotif": "BkgPPXuPL0tAJouY4UBiF",
	"selectAll": "PCXQ_zrWAifxBxvNsNVIP",
	"exitButton": "_1u-WH7hn9msmDWt68t4Qe2",
	"successNotif": "_3IDzIfcTkFAKWqJg5PS4O3",
	"successBold": "cw5bEryIoz8E6UUzYUZj9"
};