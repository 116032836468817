import React, { useState, useEffect, useRef } from "react";
import { i_RequestedDocumentFile, i_Document_2 } from "./../../lib/services";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Modal from "../Modal";
import styles from "./FileUploaderModal.module.scss";
import Button from "@material-ui/core/Button";
import documentTypeOptions from "./../../constants/documentTypeOptions";
import { v4 as uuidv4 } from "uuid";
import { FadeLoader } from "react-spinners";

const FileUploaderModal: React.FC<FileUploaderModalProps> = (props) => {
  const {
    showModal,
    defaultFileType,
    defaultFileLabel,
    onClose,
    onSubmit,
    dcid,
    droppedFile,
    unsetDroppedFile,
    uploadedFile,
    isEdit,
    submitting,
    otherDocumentsChoices,
    error,
    isFromDocument,
  } = props;

  const randomString = uuidv4();
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState(defaultFileLabel);
  const [fileLabel, setFileLabel] = useState(defaultFileLabel);
  const [fileType, setFileType] = useState<string>(defaultFileType);
  const [newDcid, setNewDcid] = useState<any>("");
  const fileTypeInput = useRef<any>();
  const fileLabelInput = useRef<any>();
  const fileInput = useRef<any>();

  useEffect(() => {
    if (Array.isArray(droppedFile)) {
      unsetDroppedFile(true);
      setFileName(droppedFile[0].name);
      setFileLabel(droppedFile[0].name.replace(/\.[^/.]+$/, "")); // excluding file extension
      setFile(droppedFile[0]);
    }
    if (dcid !== "") {
      setNewDcid(dcid);
    }
  }, [droppedFile, unsetDroppedFile]);

  useEffect(() => {
    if (showModal && !droppedFile) {
      setFileName("");
      setFileLabel("");
      setFileType(defaultFileType);
      setFile(undefined);
    }
    if (dcid !== "") {
      setNewDcid(dcid);
    }
  }, [showModal]); // eslint-disable-line

  useEffect(() => {
    if (uploadedFile) {
      setFileType(uploadedFile.file_type || defaultFileType || "");
      if (!isEdit) {
        setFileName(
          [
            defaultFileLabel,
            uploadedFile.file_name && uploadedFile.file_name.split(".").pop(),
          ].join(".") || ""
        );
        setFileLabel(
          [
            defaultFileLabel,
            uploadedFile.file_name && uploadedFile.file_name.split(".").pop(),
          ].join(".") || ""
        );
      }
    }
    if (dcid !== "") {
      setNewDcid(dcid);
    }
  }, [uploadedFile]);

  useEffect(() => {
    setFileLabel(defaultFileLabel);
  }, [defaultFileLabel]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const name: any = e.target.files[0].name;
      setFileName(name);
      setFile(e.target.files[0]);
      setFileLabel(name.replace(/\.[^/.]+$/, "")); // excluding the extension
    }
  };

  const onFileLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileLabel(e.target.value);
    if (fileLabelInput && fileLabelInput.current) {
      fileLabelInput.current.style = "";
    }
    if (!isEdit) {
      setFileName([e.target.value, fileName.split(".").pop()].join("."));
    }
  };

  const onFileTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFileType(e.target.value);
  };

  const onFileTypeChange2 = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewDcid(e.target.value);
  };
  const renderSelectFilType = () => {
    if (isFromDocument) {
      return (
        <select
          ref={fileTypeInput}
          className={styles.fileName}
          onClick={() => {
            if (fileTypeInput && fileTypeInput.current) {
              fileTypeInput.current.style = "";
            }
          }}
          onChange={(e) => {
            setFileType(e.target.value);
          }}
        >
          <option value="">[Select File Type]</option>

          {otherDocumentsChoices.sort().map((item) => (
            <option
              selected={item.documentType === fileType}
              value={item.documentType}
            >
              {item.documentType}
            </option>
          ))}
        </select>
      );
    } else if (defaultFileType === "") {
      return (
        <select
          ref={fileTypeInput}
          className={styles.fileName}
          onClick={() => {
            if (fileTypeInput && fileTypeInput.current) {
              fileTypeInput.current.style = "";
            }
          }}
          onChange={onFileTypeChange2}
        >
          <option value="">[Select File Type]</option>

          {otherDocumentsChoices.sort().map((item) => (
            <option value={item._id}>{item.documentType}</option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          type="text"
          read-only={true}
          disabled={true}
          className={styles.fileName}
          value={defaultFileType}
        />
      );
    }
  };
  return (
    <>
      <Modal showModal={showModal} onClose={onClose}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {submitting && (
              <div className={[styles.spinner, "loading-spinner"].join(" ")}>
                <FadeLoader
                  height={20}
                  width={5}
                  radius={10}
                  margin={10}
                  color={"#999"}
                  loading={submitting}
                />
              </div>
            )}
            <h4>Upload Client File</h4>
            <p>Select Client file and Document type</p>
            {error && (
              <Alert
                variant="filled"
                severity="error"
                className={styles.alertMessage}
              >
                {error}
              </Alert>
            )}
            <Grid container spacing={3}>
              {!isEdit && fileName && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>File Selected:</label>
                  <input
                    className={
                      file ? styles.fileNameSelected : styles.inputError
                    }
                    read-only={true}
                    type="text"
                    value={
                      file
                        ? [fileName, " - note : Double-click to replace"].join(
                            " "
                          )
                        : "Please upload file first. Double-click to replace"
                    }
                    onDoubleClick={() => {
                      setFileLabel("");
                      setFileName("");
                    }}
                  />
                </Grid>
              )}
              {!isEdit && !fileName && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>File:</label>
                  <input
                    ref={fileInput}
                    type="file"
                    className={styles.fileName}
                    onChange={onFileChange}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <label>File name:</label>
                <input
                  ref={fileLabelInput}
                  type="text"
                  className={styles.fileName}
                  onChange={onFileLabelChange}
                  value={fileLabel}
                />
                <p className={styles.tipText2}>
                  Tip: Please name your file carefully
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <label>File type:</label>
                {renderSelectFilType()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              variant="contained"
              color="primary"
              className={styles.primaryButton}
              style={{ marginLeft: 10 }}
              onClick={() => {
                // used for new files...
                let MutatedFile;
                if (file) {
                  MutatedFile = new File(
                    [file],
                    [randomString, fileName && fileName.split(".").pop()].join(
                      "."
                    ) || ""
                  );
                }
                // specific from Document page
                if (isFromDocument) {
                  if (!isEdit) {
                    // not checking file when editing inside document
                    if (!file) {
                      fileInput.current.style.backgroundColor =
                        "var(--primary-color-scale-25)";
                    }
                  }
                  if (fileLabel === "") {
                    fileLabelInput.current.style.backgroundColor =
                      "var(--primary-color-scale-25)";
                  }
                  if (fileType === "") {
                    fileTypeInput.current.style.backgroundColor =
                      "var(--primary-color-scale-25)";
                  }
                  if (isEdit) {
                    // edit
                    if (fileType !== "" && fileLabel !== "") {
                      onSubmit(null, null, fileLabel, fileType);
                    }
                  } else {
                    // new
                    if (file !== "" && fileType !== "" && fileLabel !== "") {
                      onSubmit(
                        null,
                        [randomString, fileName.split(".").pop()].join(".") ||
                          "",
                        fileLabel,
                        fileType,
                        MutatedFile
                      );
                    }
                  }
                } else {
                  if (!isEdit) {
                    // new
                    if (!file) {
                      fileInput.current.style.backgroundColor =
                        "var(--primary-color-scale-25)";
                    }
                    if (fileLabel === "") {
                      fileLabelInput.current.style.backgroundColor =
                        "var(--primary-color-scale-25)";
                    }
                    if (file !== "" && fileLabel !== "") {
                      onSubmit(
                        newDcid,
                        [randomString, fileName.split(".").pop()].join(".") ||
                          "",
                        fileLabel,
                        defaultFileType,
                        MutatedFile
                      );
                    }
                    // }
                  } else {
                    // edit
                    onSubmit(dcid, null, fileLabel, fileType);
                  }
                }
              }}
              disabled={submitting}
            >
              {submitting ? "SUBMITTING..." : "SUBMIT"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={styles.primaryButton}
              onClick={onClose}
              disabled={submitting}
            >
              CANCEL
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

interface FileUploaderModalProps {
  showModal: boolean;
  defaultFileType: string;
  defaultFileLabel: string;
  onSubmit: any;
  onClose: () => void;
  droppedFile?: File;
  unsetDroppedFile: Function;
  isEdit: boolean;
  uploadedFile?: i_RequestedDocumentFile;
  submitting: boolean;
  otherDocumentsChoices: i_Document_2[];
  error?: string | undefined | null;
  isFromDocument?: boolean;
  dcid?: string | null;
}

export default FileUploaderModal;
