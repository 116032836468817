import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

export const useAsteriskStyles = makeStyles({
  red: {
    color: 'var(--error-color)',
    fontWeight: "bold",
  },
});

export type AsteriskProps = {
  isVisible?: boolean;
};

export default function Asterisk({ isVisible }: AsteriskProps) {
  const styles = useAsteriskStyles();

  return isVisible ? <span className={styles.red}>*</span> : <></>;
}
