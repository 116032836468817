import React, { useEffect, useState } from "react";
import { SendEmailModalProps } from "./../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface";
import Modal from "./../Modal";
import styles from "../AddRequestModal/AddRequestModal.module.scss";
import { Button, MenuItem, Grid, Select, Input } from "@material-ui/core";

const SendEmailModal: React.FC<SendEmailModalProps> = (props) => {
  const { showModal, onSubmit, onClose, defaultEmail } = props;
  const [email, setEmail] = useState(defaultEmail);
  const [isError, setError] = useState(false);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setEmail(e.target.value);
  };

  const handleSubmit = (email: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = regex.test(email);
    if (isValid) onSubmit(email);
    else setError(true);
  };

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h3>Request Ready to be Sent to Client</h3>
              <h5>Who do you want to be notified once Request Completed?</h5>
              <input
                type="email"
                className={styles.inputEmail}
                value={email}
                onChange={onEmailChange}
              />
              {isError && <div className="text-danger">Email is invalid</div>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            color="primary"
            className={styles.primaryButton}
            style={{ marginLeft: 10  }}
            onClick={() => handleSubmit(email)}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.primaryButton}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default SendEmailModal;
