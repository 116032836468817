import Cookies from "js-cookie";
import _ from "lodash";
import action_messages from "./constants";
import { i_clientName } from "../scenes/DocumentCollection/Documents/Documents.interfaces";

export interface i_RequestedDocumentFile {
  name?: any;
  file_name?: string;
  file_type?: string;
  label?: string;
  ETag?: string;
  createdAt?: string;
  updatedAt?: string;
  _id?: string;
}

export interface i_RequestedDocument {
  dcid: any;
  documentLabel: string;
  documentType: string;
  notes?: string | undefined;
  files?: i_RequestedDocumentFile[];
}

export interface i_Document {
  key: number;
  isDuplicate: boolean;
  documentType: string;
  label: string;
  _id: string;
  isSubmitted: boolean;
  status: string;
  notes?: string;
  files?: i_RequestedDocumentFile[];
  fileType?: string;
  dcid?: string | any;
  uid?: string;
}

export interface i_DocumentType {
  [name: string]: i_Document[];
}

export interface i_Document_2 {
  documentType: string;
  label: string;
  _id: string;
}

export interface i_RequestedDocument_2 {
  dcid: i_Document_2;
  length: number;
  notes?: string;
  files?: i_RequestedDocumentFile[];
  _id: string;
}

export interface i_DocumentType_2 {
  [name: string]: i_RequestedDocument_2[];
}

export const groupDocumentsByDocumentType = (arr: i_Document[]) =>
  arr.reduce((map: i_DocumentType, obj: i_Document) => {
    const { documentType } = obj;
    if (!map[documentType]) {
      map[documentType] = [obj];
    } else {
      map[documentType].push(obj);
    }
    return map;
  }, {});

export const groupRequestedDocumentsByDocumentType = (arr: i_Document[]) =>
  arr.reduce((map: i_DocumentType, obj: i_Document) => {
    obj.key = arr.indexOf(obj);
    obj.isDuplicate = false;
    _.map(map, (item) => {
      item.map((i) => {
        if (i._id === obj._id) {
          i.isDuplicate = true;
          obj.isDuplicate = true;
        }
      });
    });

    const { documentType } = obj;
    if (!map[documentType]) {
      map[documentType] = [obj];
    } else {
      map[documentType].push(obj);
    }
    return map;
  }, {});

export const getAllDocumentsWithCategories = (clientId: string) =>
  _fetch(`mbworkbench/getAllDocumentsWithCategories/${clientId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((res) => res.json());

export const getAllSettingsOther = () =>
  _fetch(`mbworkbench/getAllSettingsOther`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((res) => res.json());

export const getDocumentType = (arr: i_Document[]) =>
  arr.reduce((newArr: string[], obj: i_Document) => {
    if (!newArr.includes(obj.documentType)) newArr.push(obj.documentType);
    return newArr;
  }, []);

export const fetchGeneralDocumentList = () =>
  _fetch("mbworkbench/getGeneralDocumentList", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((json) => json || {} || []);

export const addGeneralDocument = (
  documentType: string,
  label: string,
  uid?: string
) =>
  _fetch("mbworkbench/addGeneralDocument", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ documentType, label, uid }),
  }).then((res) => res.json());

export const sendRequestDocument = (
  requested_documents: i_RequestedDocument[],
  uid?: string,
  email?: string,
  status?: string
) =>
  _fetch("mbworkbench/sendRequestDocumentToClient", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ uid, requested_documents, email, status }),
  }).then((res) => res.json());

export const getDocumentRequests = (clientId?: string) =>
  _fetch(
    `mbworkbench/getDocumentRequestsFromAdvisor?uid=${clientId}&status=submitByAdmin`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  )
    .then((res) => res.json())
    .then((json) => {
      const { requested_documents, notes } = json[0] || {};
      return { notes, requests: requested_documents };
    });

export const getSavedDocuments = (clientId?: string) =>
  _fetch(
    `mbworkbench/getDocumentRequestsFromAdvisor?uid=${clientId}&status=savedByAdmin`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  )
    .then((res) => res.json())
    .then((json) => {
      const { requested_documents, notes } = json[0] || {};
      return { notes, savedDocuments: requested_documents };
    });

export const addNoRequestOtherDocuments = (obj: object) =>
  _fetch("mbworkbench/addNoRequestOtherDocuments", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...obj }),
  }).then((res) => res.json());

export const updateDocumentCollection = (obj: object) => {
  return _fetch("mbworkbench/updateDocumentCollection", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...obj }),
  }).then((res) => res.json());
};

export const updateOtherDocuments = (obj: object) =>
  _fetch("mbworkbench/updateOtherDocuments", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...obj }),
  }).then((res) => res.json());

const _uploadDocument = async (formData: any) => {
  return await _fetch("aws/uploadAWSFile", {
    method: "POST",
    body: formData,
  });
};

export const uploadDocument = async (
  file: any,
  docType: string,
  clientId?: any,
  client_email?: any
): Promise<any> => {
  const formData = new FormData();
  formData.append("clientDoc", file);
  formData.append("clientDocType", docType);
  formData.append("clientId", clientId);
  formData.append("clientEmail", client_email);
  formData.append("Folder", "Mortgage Broking");

  return _uploadDocument(formData).then((res) => res.json());
};

const _deleteFile = async (file_name: String, clientId?: string) => {
  return await _fetch("aws/deleteAWSFile", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      FileKey: ["DocumentCollection", clientId, file_name].join("/"),
    }),
  });
};

export const DeleteFile = (file_name: String, clientId?: any) => {
  // Delete ang File
  _deleteFile(file_name, clientId).then((res) => res.json());

  // I delete ang DB reference
  return _fetch("mbworkbench/unsetFileInDocument", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ file_name, clientId }),
  }).then((res) => res.json());
};

export const deleteDocumentRecord = (clientId: any) => {
  return _fetch("mbworkbench/deleteDocumentRecord", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ clientId }),
  }).then((res) => res.json());
};

const _downloadFile = async (
  key: String,
  clientId?: string | null,
  callback?: Function,
  folder?: string | null
) => {
  if (clientId) {
    key = key + "&clientId=" + clientId;
  }
  if (folder) {
    key = key + "&folder=" + folder;
  }

  _fetch("aws/downloadClientFile?FileName=" + key, {
    method: "GET",
  })
    .then((res) => (res.status === 200 ? res.arrayBuffer() : res.json()))
    .then((res) => {
      if (res.error) {
        throw res;
      } else {
        callback && callback(res);
      }
    })
    .catch((res) => {
      if (res.error === "infected") {
        alert(
          "We have detected that this file is infected with a virus. For your safety we have prevented it from downloading. Please contact Empower Wealth with the details of the file so we can take further action."
        );
      } else {
        alert(
          "A general error has occurred with the file download. Please contact Empower Wealth with the details of the file so we can take further action."
        );
      }
    });
};

export const ViewFile = (key: String, clientId?: string, folder?: string) => {
  const callback = (res: any) => {
    let contentType = "application/pdf";
    let fileExtension = key.split(".").pop() || "";
    switch (fileExtension.toLowerCase()) {
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
      case "png":
        contentType = "image/png";
    }
    const file = new Blob([res], { type: contentType });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };
  _downloadFile(key, clientId, callback, folder);
};

export const DownloadZipFile = async (
  clientName: i_clientName,
  clientId: string
) => {
  const firstName = clientName.firstName.replace(/\s/g, "");
  const lastName = clientName.lastName.replace(/\s/g, "");
  const clientWholeName = `${firstName}-${lastName}`;
  const today = new Date();
  const timeStampDay = today.getDate();
  const timeStampMonth = today.getMonth() + 1;
  const timeStampYear = today.getFullYear();
  const dateTimeStamp = `${timeStampDay}-${timeStampMonth}-${timeStampYear}`;
  const zipFileName = `MyWealthPortal-${clientWholeName}-${dateTimeStamp}`;
  const key = "zip";
  const folder = "Mortgages";
  const callback = (res: any) => {
    const file = new Blob([res], {});
    const fileURL = URL.createObjectURL(file);
    const anchorTag = document.createElement("a");
    anchorTag.href = fileURL;
    anchorTag.target = "_blank";
    anchorTag.setAttribute(
      "download",
      [zipFileName, key.toString().split(".").pop()].join(".")
    );
    document.body.appendChild(anchorTag);
    anchorTag.click();
    document.body.removeChild(anchorTag);
  };
  await _downloadFile(`${clientId}-documents.zip`, clientId, callback, folder);
  return "SUCCESS";
};

export const DownloadFile = (
  key: String,
  label: string,
  clientId?: string,
  folder?: string
) => {
  const callback = (res: any) => {
    const file = new Blob([res], {});
    //trick to download store a file having its URL
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    a.setAttribute(
      "download",
      [label, key.toString().split(".").pop()].join(".")
    );
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  _downloadFile(key, clientId, callback, folder);
};

interface downloadMultiple {
  key: String;
  label: string;
  clientId?: string;
  folder?: string;
}

export const DownloadFileMultiple = async (
  multipleFiles: downloadMultiple[],
  clientId: string
) => {
  const bulkUploadResponse = await _fetch("aws/downloadMultiple", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      clientId,
      requestedFiles: multipleFiles,
    }),
  }).then((res) => res.json());
  const errorMessages = Object.keys(action_messages);
  if (errorMessages.includes(bulkUploadResponse)) return bulkUploadResponse;
  return bulkUploadResponse;
};

export const asyncForEach = async (array: any[], callback: Function) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getClientNames = async (url: string) => {
  return await (await _fetch(url)).json();
};

const _fetch = (path: string, init: RequestInit = {}) => {
  const token = Cookies.get("token");
  const api_endpoint = `https://${window.location.hostname}/api/`;
  return fetch(api_endpoint + path, {
    ...init,
    referrer: window.location.href,
    headers: {
      ...init.headers,
      originreferer: window.location.href,
      Authorization: `Bearer ${token}`,
    },
  });
};
