import React from "react"
import { Grid } from "@material-ui/core"
import Modal from "../Modal"
interface SuccesModalProps {
  status: string
  showModal: boolean
  onClose: () => void
}

const SuccessModal: React.FC<SuccesModalProps> = props => {
  return (
    <Modal showModal={props.showModal} onClose={props.onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {props.status === "submitByAdmin" ? (
            <h3 style={{ textAlign: "center" }}>
              Request is now available for the client to Action. <br />
              Please email client to let them know!
            </h3>
          ) : (
            <h3 style={{ textAlign: "center" }}>
              Documents are saved but not requested!
            </h3>
          )}
        </Grid>
      </Grid>
    </Modal>
  )
}

export default SuccessModal
