import React, { useEffect, useState } from "react";
import FileUploaderModal from "../../../components/FileUploaderModal/FileUploaderModal";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import FileUploader from "../../../components/FileUploader/index";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import FileItem from "../../../components/FileItem/FileItem";
import { addFileRecords } from "./../Documents/Documents.controller";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { i_ClientResponse } from "./ClientReponse.interface";
import Typography from "@material-ui/core/Typography";
import styles from "./ClientResponse.module.scss";
import Button from "@material-ui/core/Button";
import Modal from "../../../components/Modal";
import Grid from "@material-ui/core/Grid";
import { FadeLoader } from "react-spinners";
import _ from "lodash";

import {
  getDocumentRequests,
  uploadDocument,
  fetchGeneralDocumentList,
  i_Document,
  i_RequestedDocument,
  i_RequestedDocumentFile,
  updateDocumentCollection,
  deleteDocumentRecord,
  asyncForEach,
} from "../../../lib/services";
import moment from "moment";

const ClientResponse: React.FC<i_ClientResponse> = ({
  clientId,
  client_email,
  setCurrentPage,
}) => {
  // =================================[ State ]==========================================

  const [uploadedFile, setUploadedFile] = useState<
    i_RequestedDocumentFile | undefined
  >();
  const [requestedDocuments, setRequestedDocuments] = useState<i_Document[]>(
    []
  );
  const [modalError, setModalError] = useState<string | undefined | null>();
  const [showFileUploaderModal, setShowFileUploaderModal] = useState(false);
  const [droppedFile, setDroppedFile] = useState<File | undefined>();
  const [isModalSubmitting, setIsModalSubmitting] = useState(false);
  const [currentFileEdited, setCurrentFileEdited] = useState("");
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false);
  const [modalDocumentId, setModalDocumentId] = useState("");
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("");
  const [modalIsEdit, setModalIsEdit] = useState(false);
  const [fileLabel, setFileLabel] = useState("");
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // =================================[ Functions ]==========================

  const refreshRequestedDocuments = () => {
    return new Promise(function (resolve, reject) {
      Promise.all([
        fetchGeneralDocumentList(),
        getDocumentRequests(clientId),
      ]).then(([_, { requests, notes }]) => {
        if (typeof requests !== "undefined" && requests !== null) {
          const requested = requests.map((document: i_RequestedDocument) => {
            // NEW : no linked dcid from settings
            if (!document.dcid)
              return {
                ...document,
                dcid: {
                  documentType: document.documentType,
                  label: document.documentLabel,
                },
                notes: document.notes,
                files: document.files || [],
              };
            // LEGACY : label and type is fetched from settings
            return {
              ...document,
              notes: document.notes,
              files: document.files || [],
            };
          });
          setRequestedDocuments(requested);
          resolve(true);
        } else {
          setRequestedDocuments([]);
          resolve(true);
        }
      });
    });
  };
  const updateUploadFile = (dcid: string, newItem: i_Document) => {
    const itemIndex = requestedDocuments.findIndex((d) => d._id === dcid);
    const newRequestedDocuments = [
      ...requestedDocuments.slice(0, itemIndex),
      newItem,
      ...requestedDocuments.slice(itemIndex + 1),
    ];
    setRequestedDocuments(newRequestedDocuments);
    setShowFileUploaderModal(false);
  };
  const onFileUploaderModalSubmit = (
    dcid: string,
    fileName: string,
    label: string,
    fileType: string,
    file?: File | undefined
  ) => {
    const item = requestedDocuments.find((d) => d._id === dcid);
    if (modalIsEdit && item && item.files) {
      const { files } = item;
      let file = files.find((f) => f._id === currentFileEdited);
      const fileIndex = files.findIndex((f) => f._id === currentFileEdited);

      if (file) {
        item.files[fileIndex] = {
          ...item.files[fileIndex],
          label: label,
          file_type: fileType,
          updatedAt: moment.utc().local().format("YYYY-MM-DD"),
        };

        updateDocumentCollection({
          uid: clientId,
          status: "submitByAdmin",
          dcdata: requestedDocuments.map((d) => ({
            dcid: d.dcid,
            files: d.files,
          })),
        })
          .then(function () {
            setFileLabel("");
            refreshRequestedDocuments();
            setShowFileUploaderModal(false);
          })
          .catch(() => {
            setIsModalSubmitting(false);
            setModalError("Unable to modify details");
          });
      }
    } else if (item && file) {
      setIsModalSubmitting(true);
      setModalError("");
      uploadDocument(file, fileType, clientId, client_email)
        .then(({ data }) => {
          item.files = [
            ...(item.files || []),
            {
              file_name: fileName,
              ETag: data.ETag,
              label,
              createdAt: moment.utc().local().format("YYYY-MM-DD"),
              updatedAt: moment.utc().local().format("YYYY-MM-DD"),
            },
          ];
          if (!data.error) {
            updateUploadFile(dcid, item);
            updateDocumentCollection({
              uid: clientId,
              status: "submitByAdmin",
              dcdata: requestedDocuments.map((document) => ({
                ...document,
                dcid: document.dcid,
                files: document.files,
              })),
            }).then(function () {
              refreshRequestedDocuments();
              setIsModalSubmitting(false);
            });
          } else {
            setModalError("Unable to connect with AWS S3. " + data.error.code);
          }
        })
        .catch((err) => {
          setIsModalSubmitting(false);
          setModalError("Unable to upload document. " + err.error.code);
        });
    } else setShowFileUploaderModal(false);
  };
  const onCompleteRequestClick = () => {
    setShowSubmitModal(true);
  };
  const onFileUploaderModalOpen =
    (
      dcid: string,
      isEdit: boolean,
      defaultFileType: string,
      fileLabel?: string,
      fileId?: string
    ) =>
    () => {
      setModalDocumentId(dcid);
      setModalIsEdit(isEdit);

      if (fileLabel) {
        const document = requestedDocuments.find((d) => d._id === dcid);
        if (document && document.files) {
          const file = document.files.find((f) => f.label === fileLabel);
          setUploadedFile(file);
        }
        setFileLabel(fileLabel);
      }

      fileId && setCurrentFileEdited(fileId);
      setFileType(defaultFileType);
      setShowFileUploaderModal(true);
    };

  const completeRequestYesHandler = () => {
    // Add document collection
    setIsLoading(true);
    addFileRecords({
      requestedDocuments: requestedDocuments,
      clientId: clientId,
      isSingle: false,
    }).then((res) => {
      if (!_.isNil(res)) {
        const message = res.message ? res.message : "";
        setSubmitSuccessMessage(message);
        setShowSubmitSuccessModal(true);
        refreshRequestedDocuments();
        setShowSubmitModal(false);
        setIsLoading(false);
      } else {
        setSubmitSuccessMessage(
          "Something is wrong on the uploading process. Please try again."
        );
        setShowSubmitSuccessModal(true);
        setShowSubmitModal(false);
        setIsLoading(false);
      }
    });
  };

  // =================================[ Hooks ]==========================

  useEffect(() => {
    refreshRequestedDocuments();
  }, [clientId]);

  // =================================[ Renders ]==========================

  const renderCard = (item: i_Document) => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon
                style={{ fill: "var(--primary-color)", fontSize: 30 }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={styles.heading}>
              {item.dcid.label}
              {typeof item.notes !== "undefined" ? (
                <p className={styles.subHeading}>{item.notes}</p>
              ) : null}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <FileUploader
                  type="modal-details"
                  openModalUpload={onFileUploaderModalOpen(
                    item._id,
                    false,
                    item.dcid && item.dcid.documentType
                  )}
                  droppedFile={(file: File) => {
                    if (file) {
                      setDroppedFile(file);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <FileItem
                  item={item}
                  display={{
                    view: true,
                    download: true,
                    isShowIconEdit: true,
                    isShowIconDelete: true,
                    isShowCheckbox: false,
                  }}
                  onFileUploaderModalOpen={onFileUploaderModalOpen}
                  refreshRequestedDocuments={refreshRequestedDocuments}
                  clientId={clientId}
                  isFromDocument={false}
                  folder="Mortgages"
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

  const renderSubmitModal = () => (
    <Modal
      showModal={showSubmitModal}
      onClose={() => setShowSubmitModal(false)}
    >
      <React.Fragment>
        {isLoading && (
          <div className={[styles.spinner, "loading-spinner"].join(" ")}>
            <FadeLoader
              height={20}
              width={5}
              radius={10}
              margin={10}
              color={"#999"}
              loading={isLoading}
            />
          </div>
        )}
        <h5 id="transition-modal-title">
          Are you sure you have uploaded all the documents?
        </h5>
        <p id="transition-modal-description">
          Only click yes if you are sure you have uploaded all requested
          documents.
        </p>
        <Button
          variant="contained"
          color="primary"
          className={styles.primaryButton}
          style={{ marginLeft: 10, marginTop: 10 }}
          onClick={completeRequestYesHandler}
        >
          YES, I'M SURE
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={styles.primaryButton}
          style={{ marginTop: 10 }}
          onClick={() => setShowSubmitModal(false)}
        >
          NOT YET
        </Button>
      </React.Fragment>
    </Modal>
  );
  const renderSubmitSuccessModal = () => (
    <Modal
      showModal={showSubmitSuccessModal}
      onClose={() => setShowSubmitSuccessModal(false)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3>{submitSuccessMessage}</h3>
        </Grid>
      </Grid>
    </Modal>
  );
  return (
    <>
      {requestedDocuments.length > 0 ? (
        <>
          <div className={styles.root1}>
            {requestedDocuments.map((item, index) => renderCard(item))}
          </div>
          <div className={styles.root2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                &nbsp;
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.sendButton}
                  onClick={onCompleteRequestClick}
                >
                  Complete Request
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <h5 className={styles.noRequestText}>No requests found.</h5>
      )}
      <FileUploaderModal
        showModal={showFileUploaderModal}
        defaultFileType={fileType}
        defaultFileLabel={fileLabel}
        onClose={() => {
          setShowFileUploaderModal(false);
          setFileLabel("");
        }}
        onSubmit={onFileUploaderModalSubmit}
        dcid={modalDocumentId}
        droppedFile={droppedFile}
        uploadedFile={uploadedFile}
        isEdit={modalIsEdit}
        unsetDroppedFile={(toUnset: Boolean) => {
          if (toUnset) setDroppedFile(undefined);
        }}
        submitting={isModalSubmitting}
        otherDocumentsChoices={[]}
        error={modalError}
      />
      {renderSubmitModal()}
      {renderSubmitSuccessModal()}
    </>
  );
};

export default ClientResponse;
