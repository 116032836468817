import React, { useEffect, useState } from "react"
import { DownloadFileMultiple, DownloadZipFile } from "../../lib/services"
import downloadIcon from "../../assets/download-solid.svg"
import styles from "./MultiDownloadButton.module.scss"
import action_messages from "../../lib/constants"
import { FadeLoader } from "react-spinners"
import { i_clientName } from "../../scenes/DocumentCollection/Documents/Documents.interfaces"

const MultiDownloadButton = (props: any) => {
  const {
    SUCCESS,
    SUCCESS_MESSAGE,
    INFECTED,
    INFECTED_MESSAGE,
    GENERAL_ERROR,
    GENERAL_ERROR_MESSAGE,
    NONE_SELECTED_MESSAGE,
    DOWNLOAD_ERROR,
    DOWNLOAD_ERROR_MESSAGE,
  } = action_messages
  const {
    checkedDownload,
    clientId,
    clientName,
    setCheckedDownload,
    removeChecked,
    setShowSubmitSuccessModal,
    setSubmitSuccessMessage,
  } = props
  const [showEmptyArrayNotif, setShowEmptyArrayNotif] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const awaitId = clientId ?? ""
  const downloadMultipleHandler = async (
    files: any[],
    uid: string,
    clientName: i_clientName
  ) => {
    setIsLoading(true)
    if (files.length <= 0) {
      setShowEmptyArrayNotif(true)
      setErrorMessage(NONE_SELECTED_MESSAGE)
    } else {
      setSubmitSuccessMessage("Downloading...")
      const multipleUploader = await DownloadFileMultiple(
        checkedDownload,
        awaitId
      )
      switch (multipleUploader.error) {
        case INFECTED: {
          setShowEmptyArrayNotif(true)
          setErrorMessage(INFECTED_MESSAGE)
          setIsLoading(false)
          break
        }
        case GENERAL_ERROR: {
          setShowEmptyArrayNotif(true)
          setErrorMessage(GENERAL_ERROR_MESSAGE)
          setIsLoading(false)
          break
        }
        case DOWNLOAD_ERROR: {
          setShowEmptyArrayNotif(true)
          setErrorMessage(DOWNLOAD_ERROR_MESSAGE)
          setIsLoading(false)
          break
        }
      }
      if (multipleUploader.message === "UPLOAD_SUCCESS") {
        setTimeout(async () => {
          await DownloadZipFile(clientName, awaitId)
          setIsLoading(false)
          setShowSuccessMessage(true)
          setSuccessMessage(SUCCESS_MESSAGE)

          removeChecked(true)
        }, 10000)
      }
    }
  }
  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false)
        setCheckedDownload([])
      }, 4000)
    }
  }, [showSuccessMessage])
  return (
    <>
      {isLoading && (
        <div className={[styles.spinner, "loading-spinner"].join(" ")}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color="#999"
            loading={isLoading}
          />
        </div>
      )}
      <div className={styles.downloadAllContainer}>
        <img
          onClick={() =>
            downloadMultipleHandler(checkedDownload, awaitId, clientName)
          }
          src={downloadIcon}
          className={styles.downloadIcon}
          alt="download-icon"
        />
        <p
          className={styles.selectAll}
          onClick={() =>
            downloadMultipleHandler(checkedDownload, awaitId, clientName)
          }
        >
          Download Selected
        </p>
      </div>
      {showEmptyArrayNotif ? (
        <div className={styles.errorNotif}>
          <span
            className={styles.exitButton}
            onClick={() => setShowEmptyArrayNotif(false)}
          >
            &times;
          </span>
          <p>{errorMessage}</p>
        </div>
      ) : null}
      {showSuccessMessage ? (
        <div className={styles.successNotif}>
          <p>
            <span
              className={styles.exitButton}
              onClick={() => setShowSuccessMessage(false)}
            >
              &times;
            </span>
            <span className={styles.successBold}>Success! </span>
            {successMessage}
          </p>
        </div>
      ) : null}
    </>
  )
}
export default MultiDownloadButton
