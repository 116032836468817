import React, { useEffect, useState, ChangeEvent } from "react";
import _ from "lodash";
import AddRequestModal from "./../../../components/AddRequestModal/AddRequestModal";
import RequestItem from "../../../components/RequestItem/RequestItem";
import { i_AdminRequest } from "./AdminRequest.interface";
import { orange } from "@material-ui/core/colors";
import styles from "./AdminRequest.module.scss";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";

import {
  fetchGeneralDocumentList,
  groupDocumentsByDocumentType,
  groupRequestedDocumentsByDocumentType,
  i_Document,
  getDocumentType,
  addGeneralDocument,
  getDocumentRequests,
  getSavedDocuments,
  i_RequestedDocument,
} from "../../../lib/services";
import {
  AppBar,
  Button,
  Typography,
  List,
  ListItem,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import SendEmailModal from "../../../components/SendEmailModal/SendEmailModal";

const plusIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
      fill="var(--primary-color)"
    />
  </svg>
);

const AdminRequest: React.FC<i_AdminRequest> = ({
  setContainerToHaveChanges,
  user_email,
  onSubmit,
  clientId,
  triggerSave,
  triggerDiscard,
  toStateProceed,
  stateToChange,
}) => {
  // =================================[ State ]====================================

  const [requestedDocuments, setRequestedDocuments] = useState<i_Document[]>(
    []
  );
  const [isModalRequested, setIsModalRequested] = useState<boolean>(false);
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string[]>([]);
  const [documents, setDocuments] = useState<i_Document[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [fetchedDocuments, setFetchedDocuments] = useState<i_Document[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<i_Document[]>([]);

  // =================================[ Functions ]==========================

  const documentsList = groupDocumentsByDocumentType(
    documents.filter((d: any) => !d.uid).filter((d: any) => d._id)
  );

  const addDocToList = (document: i_Document) => {
    setRequestedDocuments([...requestedDocuments, document]);
    setSelectedDocuments([...selectedDocuments, document]);
  };

  const addToList = (e: React.MouseEvent, document: i_Document) => {
    addDocToList({ ...document, notes: "" });
  };

  const removeFromList = (e: React.MouseEvent, document: i_Document) => {
    const reqDocumentsList = requestedDocuments.filter(
      (d) => d.key !== document.key
    );
    setRequestedDocuments(reqDocumentsList);

    const selectReqDocumentsList = selectedDocuments.filter(
      (d) => d.key !== document.key
    );
    setSelectedDocuments(selectReqDocumentsList);
  };

  const onNotesChange = (key: number) => (value: string) => {
    const item = _.cloneDeep(requestedDocuments.find((d) => d.key === key));
    if (!item) return;
    item.notes = value;
    const itemIndex = requestedDocuments.findIndex((d) => d.key === key);
    const newRequestedDocuments = [
      ...requestedDocuments.slice(0, itemIndex),
      item,
      ...requestedDocuments.slice(itemIndex + 1),
    ];
    setRequestedDocuments(newRequestedDocuments);

    const itemS = _.cloneDeep(selectedDocuments.find((d) => d.key === key));
    if (!itemS) return;
    itemS.notes = value;
    const itemIndexS = selectedDocuments.findIndex((d) => d.key === key);
    const newSelectedDocuments = [
      ...selectedDocuments.slice(0, itemIndexS),
      item,
      ...selectedDocuments.slice(itemIndexS + 1),
    ];
    setSelectedDocuments(newSelectedDocuments);
  };

  const onModalSubmit = (documentType: string, fileName: string) => {
    const uid = isModalRequested ? clientId : undefined;
    addGeneralDocument(documentType, fileName, uid).then(() => {
      fetchGeneralDocumentList().then((res) => {
        setDocuments(
          res.filter((doc: i_Document) => !doc.uid && doc.status === "active")
        );
        const document = res.find((doc: i_Document) => doc.label === fileName);
        if (document) {
          addDocToList(document);
        }
      });
      setShowModal(false);
    });
  };

  const onModalClose = () => setShowModal(false);

  const onModalOpen = (isRequestedDoc: boolean) => () => {
    setIsModalRequested(isRequestedDoc);
    setShowModal(true);
  };

  const onEmailSubmit = (email: string) => {
    onSubmit(requestedDocuments, email, "submitByAdmin").then(() => {
      setRequestedDocuments([]);
      setShowEmailModal(false);
      init();
    });
  };

  const saveDocuments = () => {
    onSubmit(selectedDocuments, null, "savedByAdmin").then(() => {
      init();
      if (stateToChange) {
        setContainerToHaveChanges(false);
        toStateProceed();
      }
    });
  };

  const discardDocuments = () => {
    init();
  };
  const init = () => {
    Promise.all([
      fetchGeneralDocumentList(),
      getDocumentRequests(clientId),
      getSavedDocuments(clientId),
    ]).then(([docs, { requests }, { savedDocuments }]) => {
      setDocuments(docs.filter((doc: i_Document) => doc.status === "active"));
      setDocumentType(getDocumentType(docs));

      if (requests) {
        setHasRequested(requests.length > 0);
      }
      if (savedDocuments) {
        const saved = savedDocuments.map((document: i_RequestedDocument) => {
          // NEW : no linked dcid from settings
          if (!document.dcid) {
            return {
              documentType: document.documentType,
              label: document.documentLabel,
              notes: document.notes,
            };
          } else {
            // LEGACY : label and type is fetched from settings
            return {
              ...document.dcid,
              notes: document.notes,
            };
          }
        });
        setRequestedDocuments(saved);
        setFetchedDocuments(saved);
        setSelectedDocuments(saved);
      } else {
        setRequestedDocuments([]);
        setFetchedDocuments([]);
        setSelectedDocuments([]);
      }
    });
  };
  // =================================[ Hooks ]==========================
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    console.log({ triggerSave, triggerDiscard });
    if (triggerSave) {
      saveDocuments();
    }
    if (triggerDiscard) {
      discardDocuments();
    }
  }, [triggerSave, triggerDiscard]);

  // =================================[ Renders ]==========================
  const renderDocumentType =
    (isRequested: boolean) =>
    (documentsOnDocumentType: i_Document[], documentType: string) => {
      return (
        <React.Fragment>
          <ListItem
            key={`list-item-documentType-${documentType}`}
            component="a"
            className={styles.listDocumentType}
          >
            {documentType}
          </ListItem>
          {documentsOnDocumentType.map((document, i) => (
            <ListItem key={`list-item-${i}`}>
              {document.label}
              <div>
                {isRequested && (
                  <RequestItem
                    onEdit={onNotesChange(document.key)}
                    notes={document.notes || null}
                    isDuplicate={document.isDuplicate}
                  />
                )}
                {isRequested ? (
                  <IconButton
                    key={`document-${document._id}`}
                    onClick={(e) => removeFromList(e, document)}
                    classes={{ root: styles.iconButtons }}
                  >
                    <RemoveCircleOutlineOutlinedIcon
                      style={{ color: "var(--primary-color)", fontSize: 30 }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    key={`document-${document._id}`}
                    onClick={(e) => addToList(e, document)}
                    classes={{ root: styles.iconButtons }}
                  >
                    <AddCircleOutlineIcon
                      style={{ color: "var(--primary-color)", fontSize: 30 }}
                    />
                  </IconButton>
                )}
              </div>
            </ListItem>
          ))}
        </React.Fragment>
      );
    };
  const isSame = (fetched: any, r: any) => {
    if (fetched && r) {
      if (_.isEqual(fetched, r)) {
        setContainerToHaveChanges(false);
        return true;
      }
      setContainerToHaveChanges(true);
      return false;
    } else {
      setContainerToHaveChanges(false);
      return true;
    }
  };
  const requestedDocumentsList =
    groupRequestedDocumentsByDocumentType(requestedDocuments);
  return (
    <>
      <Button
        variant="contained"
        className={styles.sendButton}
        onClick={() => {
          setShowEmailModal(true);
        }}
      >
        Send Request to Client
      </Button>
      <div className={styles.root}>
        <div className={styles.documentsList}>
          <div style={{ flex: "2" }}>
            <AppBar position="static" className={styles.appbar}>
              <Toolbar className={styles.toolbar}>
                <Typography variant="h5">Documents List</Typography>
                <a
                  href="#"
                  className={styles.addButton}
                  onClick={onModalOpen(false)}
                >
                  {" "}
                  {/* eslint-disable-line */}
                  {plusIcon}
                </a>{" "}
              </Toolbar>
            </AppBar>
            <List className={styles.listWrapper}>
              {Object.keys(documentsList).length > 0 ? (
                Object.keys(documentsList)
                  .sort()
                  .map((key) =>
                    renderDocumentType(false)(documentsList[key], key)
                  )
              ) : (
                <li>Loading...</li>
              )}
            </List>
          </div>
          <div style={{ flex: "3" }}>
            <AppBar position="static" className={styles.appbar}>
              <Toolbar className={styles.toolbar}>
                <Typography variant="h5">Requested Documents</Typography>
                <a
                  href="#"
                  className={styles.addButton}
                  onClick={onModalOpen(true)}
                >
                  {" "}
                  {/* eslint-disable-line */}
                  {plusIcon}
                </a>{" "}
              </Toolbar>
            </AppBar>

            {hasRequested && (
              <p className={styles.hasRequestNote}>
                <strong>NOTE:</strong> There is currently a request outstanding
                with this client that has not yet been fulfilled. Check the
                outstanding request <strong>BEFORE</strong> making a new
                request.
              </p>
            )}

            <List className={styles.listWrapper}>
              {Object.keys(requestedDocumentsList).length > 0
                ? Object.keys(requestedDocumentsList)
                    .sort()
                    .map((key) =>
                      renderDocumentType(true)(requestedDocumentsList[key], key)
                    )
                : !hasRequested && <li>No data yet.</li>}
            </List>

            {!isSame(fetchedDocuments, requestedDocuments) && (
              <div className={styles.bottomButton}>
                <Button
                  variant="contained"
                  className={styles.saveDiscardButton}
                  onClick={saveDocuments}
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  className={styles.saveDiscardButton}
                  onClick={discardDocuments}
                >
                  Discard
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddRequestModal
        documentType={documentType}
        showModal={showModal}
        onClose={onModalClose}
        onSubmit={onModalSubmit}
      />
      {requestedDocuments && (
        <SendEmailModal
          defaultEmail={user_email}
          showModal={showEmailModal}
          onClose={() => setShowEmailModal(false)}
          onSubmit={onEmailSubmit}
        />
      )}
    </>
  );
};

export default AdminRequest;
