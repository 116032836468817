import { _fetch } from "../../../lib/utils"

export const fetchLists = (props: any) => {
  return _fetch(`mbworkbench/document/get`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uid: props.uid,
    }),
  }).then(res => res.json())
}

export const deleteFile = (props: any) => {
  return _fetch(`mbworkbench/document/deleteFile`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uid: props.uid,
      fileId: props.fileId,
    }),
  })
    .then(res => res.json())
    .then(res => {
      // check if needs to delete the entire document
      fetchLists({ uid: props.uid }).then(res2 => {
        if (res2[res2.length - 1].files.length === 0) {
          _fetch(`mbworkbench/document/deleteRecord`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              uid: props.uid,
            }),
          })
        }
      })
    })
}

export const editFile = (props: any) => {
  return _fetch(`mbworkbench/document/edit`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uid: props.uid,
      fileId: props.fileId,
      fileLabel: props.fileLabel,
      documentType: props.documentType,
    }),
  })
}

export const addFileRecords = (props: any | undefined) => {
  const { requestedDocuments, clientId, isSingle } = props
  return _fetch(`mbworkbench/document/updateDocumentCollection`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uid: clientId,
      requestedDocuments: requestedDocuments,
      isSingle,
    }),
  }).then(response => response.json())
}
