import { i_ACStates } from "./interface"
import {
  getPer,
  getNumOf,
  getAvePer,
  getIncomeByPercent,
  getYield,
  formatMoney,
} from "./calculations"
import { _fetch } from "./../../../../lib/utils"
import _ from "lodash"
import {
  incomeTypeOptions,
  periodOptions,
  ownerOptions,
  selectedOwner,
  getDefaultRowValue,
} from "./config"
import moment from "moment"

export const generateIncomeRow = (value: any) => {
  return (
    value &&
    value.map((v: any, index: any) => {
      if (index === value.length - 1) {
        return [
          {
            key: "label",
            type: "field",
            isReadOnly: true,
            val: "Previous Year Income",
          },
          {
            key: "label",
            type: "spacer",
          },
          {
            key: "previousYearIncome",
            type: "currency",
            isReadOnly: false,
            val: v.previousYearIncome,
          },
          {
            key: "label",
            type: "spacer",
          },
          {
            key: "p/w",
            type: "field",
            isReadOnly: true,
            val: getPer(v.previousYearIncome, "Yearly").week,
          },
          {
            key: "p/f",
            type: "field",
            isReadOnly: true,
            val: getPer(v.previousYearIncome, "Yearly").fortnight,
          },
          {
            key: "p/m",
            type: "field",
            isReadOnly: true,
            val: getPer(v.previousYearIncome, "Yearly").month,
          },
          {
            key: "p/a",
            type: "field",
            isReadOnly: true,
            val: getPer(v.previousYearIncome, "Yearly").year,
          },
        ]
      } else {
        const borderColor = v.type === "PAYG" ? "var(--primary-color)" : "transparent"
        return [
          {
            key: "type",
            type: "select",
            options: incomeTypeOptions,
            val: v.type,
            borderColor,
          },
          {
            key: "portal",
            type: "currency",
            isReadOnly: true,
            val: formatMoney(v.portal),
          },
          {
            key: "brokerInput",
            type: "currency",
            isReadOnly: false,
            val: v.brokerInput?.toLocaleString("en-AU", {
              minimumFractionDigits: 2,
            }),
          },
          {
            key: "period",
            type: "select",
            options: periodOptions,
            val: v.period,
          },
          {
            key: "p/w",
            type: "field",
            isReadOnly: true,
            val: getPer(v.brokerInput, v.period).week,
            isComputed: true,
          },
          {
            key: "p/f",
            type: "field",
            isReadOnly: true,
            val: getPer(v.brokerInput, v.period).fortnight,
            isComputed: true,
          },
          {
            key: "p/m",
            type: "field",
            isReadOnly: true,
            val: getPer(v.brokerInput, v.period).month,
            isComputed: true,
          },
          {
            key: "p/a",
            type: "field",
            isReadOnly: true,
            val: getPer(v.brokerInput, v.period).year,
            isComputed: true,
            isForTotal: true,
          },
          { key: "action", type: "delete" },
        ]
      }
    })
  )
}
export const generateYTDCalcRow = (value: any) => {
  let _startDate: any, _finishDate: any

  return (
    value &&
    value.map((v: any, index: any) => {
      if (index === 0) {
        _startDate = v.startDate
        _finishDate = v.finishDate
        return [
          { key: "startDate", type: "date", val: v.startDate },
          { key: "finishDate", type: "date", val: v.finishDate },
          {
            key: "days",
            type: "field",
            isReadOnly: true,
            val: getNumOf(v.finishDate, v.startDate).days + " days",
          },
          index === 0 && {
            key: "weeks",
            type: "field",
            isReadOnly: true,
            val: getNumOf(v.finishDate, v.startDate).weeks + " weeks",
          },
          {
            key: "ytdPay",
            type: "currency",
            isReadOnly: false,
            val: v.ytdPay?.toLocaleString("en-AU", {
              minimumFractionDigits: 2,
            }),
          },
          {
            key: "avep/w",
            type: "currency",
            isReadOnly: true,
            val: getAvePer({
              income: v.ytdPay,
              date1: v.finishDate,
              date2: v.startDate,
            }).week,
          },
          {
            key: "annualised",
            type: "currency",
            isReadOnly: true,
            val: getAvePer({
              income: v.ytdPay,
              date1: v.finishDate,
              date2: v.startDate,
            }).anum,
          },
        ]
      } else {
        return [
          {
            key: "incomeType",
            type: "select",
            options: incomeTypeOptions,
            colSpan: 4,
            val: v.incomeType,
          },
          {
            key: "ytdPay",
            type: "currency",
            isReadOnly: false,
            val: v.ytdPay?.toLocaleString("en-AU", {
              minimumFractionDigits: 2,
            }),
          },
          {
            key: "avep/w",
            type: "currency",
            isReadOnly: true,
            val: getAvePer({
              income: v.ytdPay,
              date1: _finishDate,
              date2: _startDate,
            }).week,
          },
          {
            key: "annualised",
            type: "currency",
            isReadOnly: true,
            val: getAvePer({
              income: v.ytdPay,
              date1: _finishDate,
              date2: _startDate,
            }).anum,
          },
          { key: "action", type: "delete" },
        ]
      }
    })
  )
}
export const generateDeductionRow = (value: any) => {
  return (
    value &&
    value.map((v: any) => [
      { key: "type", type: "field", isReadOnly: false, val: v.type },
      {
        key: "amount",
        type: "currency",
        isReadOnly: false,
        val: v.amount?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
      },
      {
        key: "period",
        type: "select",
        options: periodOptions,
        val: v.period,
      },
      {
        key: "p/w",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.amount, v.period).week,
        isComputed: true,
      },
      {
        key: "p/f",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.amount, v.period).fortnight,
        isComputed: true,
      },
      {
        key: "p/m",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.amount, v.period).month,
        isComputed: true,
      },
      {
        key: "p/a",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.amount, v.period).year,
        isComputed: true,
        isForTotal: true,
      },
      {
        key: "owner",
        type: "select",
        options: ownerOptions(v.owner),
        val: selectedOwner(v.owner),
      },
      { key: "voluntary", type: "switch", val: v.voluntary },
      { key: "action", type: "delete" },
    ])
  )
}
export const generateRentalIncomeRow = (value: any) => {
  return (
    value &&
    value.map((v: any) => {
      const color = v.address === "Proposed Property" ? "var(--primary-color)" : "default"
      return [
        {
          key: "address",
          type: "field",
          isReadOnly: true,
          val: v.address,
          color,
        },
        {
          key: "rent",
          type: "currency",
          isReadOnly: false,
          val: v.rent?.toLocaleString("en-AU", {
            minimumFractionDigits: 2,
          }),
        },
        {
          key: "period",
          type: "select",
          options: periodOptions,
          val: v.period,
        },
        {
          key: "p/w",
          type: "currency",
          isReadOnly: true,
          val: getPer(v.rent, v.period).week,
          isComputed: true,
        },
        {
          key: "p/f",
          type: "currency",
          isReadOnly: true,
          val: getPer(v.rent, v.period).fortnight,
          isComputed: true,
        },
        {
          key: "p/m",
          type: "currency",
          isReadOnly: true,
          val: getPer(v.rent, v.period).month,
          isComputed: true,
        },
        {
          key: "p/a",
          type: "currency",
          isReadOnly: true,
          val: getPer(v.rent, v.period).year,
          isComputed: true,
          isForTotal: true,
        },
        {
          key: "ownershipPercentageClient1",
          type: "percentage",
          isReadOnly: v.address !== "Proposed Property",
          val: v.ownershipPercentageClient1,
        },
        {
          key: "ownershipPercentageClient2",
          type: "percentage",
          isReadOnly: v.address !== "Proposed Property",
          val: v.ownershipPercentageClient2,
        },
        {
          key: "client1(%)",
          type: "currency",
          isReadOnly: true,
          val: getIncomeByPercent(
            getPer(v.rent, v.period).year,
            v.ownershipPercentageClient1
          ),
          isComputed: true,
          isForTotal: true,
        },
        {
          key: "client2(%)",
          type: "currency",
          isReadOnly: true,
          val: getIncomeByPercent(
            getPer(v.rent, v.period).year,
            v.ownershipPercentageClient2
          ),
          isComputed: true,
          isForTotal: true,
        },
        {
          key: "propertyValue",
          type: "currency",
          isReadOnly: v.address !== "Proposed Property",
          val: v.propertyValue?.toLocaleString("en-AU", {
            minimumFractionDigits: 2,
          }),
        },
        {
          key: "yield",
          type: "percentage",
          isReadOnly: true,
          val:
            v.propertyValue !== 0
              ? getYield({
                  annualRent: parseFloat(
                    getPer(v.rent, v.period).year?.replace(/,/g, "") || "0"
                  ), // || "0" is only for TS fixing
                  value: v.propertyValue,
                })
              : 0,
        },
        {
          key: "action",
          type: v.address === "Proposed Property" ? "delete" : "",
        },
      ]
    })
  )
}

export const generateHomeLoansRow = (value: any) => {
  return (
    value && [
      [
        { key: "address", type: "field", isReadOnly: true, val: value.address },
        { key: "lender", type: "field", isReadOnly: true, val: value.lender },
        {
          key: "loanType",
          type: "field",
          isReadOnly: true,
          val: value.loanType,
        },
        {
          key: "primaryPurpose",
          type: "field",
          isReadOnly: true,
          val: value.primaryPurpose,
        },
        {
          key: "borrower",
          type: "field",
          isReadOnly: true,
          val: value.borrower,
        },
        {
          key: "repaymentType",
          type: "field",
          isReadOnly: true,
          val: value.repaymentType,
        },
        {
          key: "loanExpiry",
          type: "field",
          isReadOnly: true,
          val: _.isEmpty(value.loanExpiry)
            ? ""
            : moment(new Date(value.loanExpiry)).format("DD-MM-YYYY"),
        },
        {
          key: "ioExp",
          type: "field",
          isReadOnly: true,
          val: _.isEmpty(value.ioExp)
            ? ""
            : moment(new Date(value.ioExp)).format("DD-MM-YYYY"),
        },
        {
          key: "fixedIO",
          type: "field",
          isReadOnly: true,
          val: _.isEmpty(value.fixedIO)
            ? ""
            : moment(new Date(value.fixedIO)).format("DD-MM-YYYY"),
        },
        { key: "isRefi", type: "switch", val: value.isRefi },
        {
          key: "balance",
          type: "currency",
          isReadOnly: true,
          val: formatMoney(value.balance),
          isComputed: value.isRefi,
          isForTotal: value.isRefi,
        },
        {
          key: "redraw",
          type: "currency",
          isReadOnly: true,
          val: formatMoney(value.redraw),
        },
        {
          key: "repayment",
          type: "currency",
          isReadOnly: false,
          val: value.repayment,
          isForTotal: value.isRefi,
          isComputed: value.isRefi,
        },
        {
          key: "period",
          type: "select",
          options: periodOptions,
          val: value.period,
        },
        {
          key: "baseRate",
          type: "percentage",
          isReadOnly: true,
          val: value.baseRate ?? 0,
        },
        {
          key: "discountRate",
          type: "percentage",
          isReadOnly: true,
          val: value.discountRate ?? 0,
        },
        {
          key: "interestRate",
          type: "percentage",
          isReadOnly: true,
          val: value.interestRate ?? 0,
        },
        {
          key: "accountNumber",
          type: "field",
          isReadOnly: true,
          val: value.accountNumber,
        },
      ],
    ]
  )
}

export const generateUnsecuredLiabilitiesRow = (value: any) => {
  return (
    value &&
    value.map((v: any) => [
      { key: "type", type: "field", isReadOnly: false, val: v.type },
      {
        key: "balance",
        type: "currency",
        isReadOnly: false,
        val: v.balance?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
      },
      {
        key: "repayment",
        type: "currency",
        isReadOnly: false,
        val: v.repayment?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
      },
      {
        key: "period",
        type: "select",
        options: periodOptions,
        val: v.period,
      },
      {
        key: "p/w",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.repayment, v.period).week,
        isComputed: true,
      },
      {
        key: "p/f",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.repayment, v.period).fortnight,
        isComputed: true,
      },
      {
        key: "p/m",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.repayment, v.period).month,
        isComputed: true,
        isForTotal: true,
      },
      {
        key: "p/a",
        type: "currency",
        isReadOnly: true,
        val: getPer(v.repayment, v.period).year,
        isComputed: true,
      },
      { key: "rate", type: "percentage", isReadOnly: false, val: v.rate },
      {
        key: "owner",
        type: "select",
        options: ownerOptions(v.owner),
        val: selectedOwner(v.owner),
      },
      { key: "action", type: "delete" },
    ])
  )
}

export const generateCreditCardRow = (value: any) => {
  return (
    value &&
    value.map((v: any) => [
      { key: "lender", type: "field", isReadOnly: false, val: v.lender },
      {
        key: "limit",
        type: "currency",
        isReadOnly: false,
        val: v.limit?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
        isComputed: true,
        isForTotal: true,
      },
      {
        key: "proposedLimit",
        type: "currency",
        isReadOnly: false,
        val: v.proposedLimit?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
        isComputed: true,
        isForTotal: true,
      },
      {
        key: "owner",
        type: "select",
        options: ownerOptions(v.owner),
        val: selectedOwner(v.owner),
      },
      { key: "action", type: "delete" },
    ])
  )
}

export const generateFinancialAssetsRow = (value: any) => {
  const getClientIncome = (v: any, clientType: string) => {
    if (selectedOwner(v.owner) === v.owner["client" + clientType]?.fullName) {
      return getPer(v.yearlyIncome, "Yearly").year
    } else if (selectedOwner(v.owner) === "Both") {
      return getIncomeByPercent(
        getPer(v.yearlyIncome, "Yearly").year,
        v["ownershipPercentageClient" + clientType]
      )
    } else {
      return 0
    }
  }
  return (
    value &&
    value.map((v: any) => [
      { key: "type", type: "field", isReadOnly: false, val: v.type },
      {
        key: "balance",
        type: "currency",
        isReadOnly: false,
        val: v.balance?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
        isForTotal: true,
        isComputed: true,
      },
      {
        key: "owner",
        type: "select",
        options: ownerOptions(v.owner),
        val: selectedOwner(v.owner),
      },
      {
        key: "incomep/m",
        type: "currency",
        isReadOnly: true,
        val: !_.isNil(v.yearlyIncome) ? _.round(v.yearlyIncome / 12, 2) : 0,
        isComputed: true,
      },
      {
        key: "yearlyIncome",
        type: "currency",
        isReadOnly: false,
        val: !_.isNil(v.yearlyIncome)
          ? v.yearlyIncome?.toLocaleString("en-AU", {
              minimumFractionDigits: 2,
            })
          : "0.00",
        isComputed: true,
        isForTotal: true,
      },
      { key: "action", type: "delete" },
      {
        key: "ownershipPercentageClient1",
        type: "hidden",
        value: v.ownershipPercentageClient1,
      },
      {
        key: "ownershipPercentageClient2",
        type: "hidden",
        value: v.ownershipPercentageClient2,
      },
      {
        key: "client1Income",
        type: "hidden",
        isReadOnly: true,
        val: getClientIncome(v, "1"),
        isComputed: true,
        isForTotal: true,
      },
      {
        key: "client2Income",
        type: "hidden",
        isReadOnly: true,
        val: getClientIncome(v, "2"),
        isComputed: true,
        isForTotal: true,
      },
    ])
  )
}

export const generateNonFinancialAssetsRow = (value: any) => {
  return (
    value &&
    value.map((v: any) => [
      { key: "type", type: "field", isReadOnly: false, val: v.type },
      {
        key: "balance",
        type: "currency",
        isReadOnly: false,
        val: v.balance?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
        isForTotal: true,
        isComputed: true,
      },
      {
        key: "owner",
        type: "select",
        options: ownerOptions(v.owner),
        val: selectedOwner(v.owner),
      },
      { key: "action", type: "delete" },
    ])
  )
}

export const generateExpensesRow = (value: any) => {
  return (
    value &&
    value.map((v: any) => [
      { key: "isOpen", type: "showMoreDetails", val: v.isOpen || false },
      { key: "expenses", type: "field", isReadOnly: false, val: v.flexType },
      {
        key: "monthlyAmount",
        type: "currency",
        isReadOnly: false,
        val: v.monthlyAmount?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
        isComputed: true,
        isForTotal: true,
      },
      {
        key: "type",
        type: "currency",
        isReadOnly: true,
        val: (
          parseFloat(v.monthlyAmount.toString().replace(/,/g, "")) / 2
        )?.toLocaleString("en-AU", {
          minimumFractionDigits: 2,
        }),
        isComputed: true,
      },
      {
        key: "items",
        type: "moredetails",
        details: v.items,
      },
    ])
  )
}

export const handleAddRow = (args: any) => {
  const { type, ACStates, setACStates, owners } = args

  switch (type) {
    case "client1.incomeBreakdown":
      const newClient1IncomeIB = _.clone(ACStates?.client1Income) || {
        incomeBreakdown: [],
      }
      const INDEX_BEFORE_PREVIOUS_YEAR_INCOME =
        newClient1IncomeIB.incomeBreakdown.length - 1
      newClient1IncomeIB.incomeBreakdown
        ? newClient1IncomeIB.incomeBreakdown.splice(
            INDEX_BEFORE_PREVIOUS_YEAR_INCOME,
            0,
            getDefaultRowValue(owners, "incomeBreakdown")
          ) // portal needs to be fetched from Erwin's update
        : (newClient1IncomeIB.incomeBreakdown = [
            getDefaultRowValue(owners, "incomeBreakdown"),
          ])
      setACStates({
        ...ACStates,
        client1Income: newClient1IncomeIB,
      })
      break
    case "client1.ytdCalculator":
      const newClient1IncomeYC = _.clone(ACStates?.client1Income) || {
        ytdCalculator: [],
      }
      newClient1IncomeYC.ytdCalculator
        ? newClient1IncomeYC.ytdCalculator.push(
            getDefaultRowValue(owners, "ytdCalculator")
          )
        : (newClient1IncomeYC.ytdCalculatr = [
            getDefaultRowValue(owners, "ytdCalculator"),
          ])
      setACStates({
        ...ACStates,
        client1Income: newClient1IncomeYC,
      })
      break
    case "client2.incomeBreakdown":
      const newClient2IncomeIB = _.clone(ACStates?.client2Income) || {
        incomeBreakdown: [],
      }
      const INDEX_BEFORE_PREVIOUS_YEAR_INCOME_SECONDARY =
        newClient2IncomeIB.incomeBreakdown.length - 1
      newClient2IncomeIB.incomeBreakdown
        ? newClient2IncomeIB.incomeBreakdown.splice(
            INDEX_BEFORE_PREVIOUS_YEAR_INCOME_SECONDARY,
            0,
            getDefaultRowValue(owners, "incomeBreakdown")
          ) // portal needs to be fetched from Erwin's update
        : (newClient2IncomeIB.incomeBreakdown = [
            getDefaultRowValue(owners, "incomeBreakdown"),
          ])
      setACStates({
        ...ACStates,
        client2Income: newClient2IncomeIB,
      })
      break
    case "client2.ytdCalculator":
      const newClient2IncomeYC = _.clone(ACStates?.client2Income) || {
        ytdCalculator: [],
      }
      newClient2IncomeYC.ytdCalculator
        ? newClient2IncomeYC.ytdCalculator.push(
            getDefaultRowValue(owners, "ytdCalculator")
          )
        : (newClient2IncomeYC.ytdCalculator = [
            getDefaultRowValue(owners, "ytdCalculator"),
          ])
      setACStates({
        ...ACStates,
        client2Income: newClient2IncomeYC,
      })
      break
    case "deductions":
      const deductions = _.clone(ACStates?.deductions) || []
      deductions.push(getDefaultRowValue(owners, "deductions"))
      setACStates({
        ...ACStates,
        deductions,
      })
      break
    case "rentalIncome":
      const rentalIncome = _.clone(ACStates?.rentalIncome) || []
      rentalIncome.push(getDefaultRowValue(owners, "rentalIncome"))
      setACStates({
        ...ACStates,
        rentalIncome,
      })
      break
    case "homeLoans":
      const homeLoans = _.clone(ACStates?.homeLoans) || []
      homeLoans.push(getDefaultRowValue(owners, "homeLoans"))
      setACStates({ ...ACStates, homeLoans })
      break
    case "unsecuredLiabilities":
      const unsecuredLiabilities = _.clone(ACStates?.unsecuredLiabilities) || []
      unsecuredLiabilities.push(
        getDefaultRowValue(owners, "unsecuredLiabilities")
      )
      setACStates({ ...ACStates, unsecuredLiabilities })
      break
    case "creditCard":
      const creditCard = _.clone(ACStates?.creditCard) || []
      creditCard.push(getDefaultRowValue(owners, "creditCard"))
      setACStates({ ...ACStates, creditCard })
      break
    case "financialAssets":
      const financialAssets = _.clone(ACStates?.financialAssets) || []
      financialAssets.push(getDefaultRowValue(owners, "financialAssets"))
      setACStates({ ...ACStates, financialAssets })
      break
    case "nonFinancialAssets":
      const nonFinancialAssets = _.clone(ACStates?.nonFinancialAssets) || []
      nonFinancialAssets.push(getDefaultRowValue(owners, "nonFinancialAssets"))
      setACStates({ ...ACStates, nonFinancialAssets })
      break
    case "expenses":
      const expenses = _.clone(ACStates?.expenses) || []
      expenses.push(getDefaultRowValue(owners, "expenses"))
      setACStates({ ...ACStates, expenses })
      break
  }
}

export const handleUpdateRow = (args: any) => {
  const { data, item, id, newValue } = args
  Object.entries(data!).forEach(([key, value]) => {
    const index = key as keyof typeof data
    const row = value as any
    if (Number(index) === id) {
      Object.keys(row).map(function(type) {
        if (type === item.key) {
          if (item.key === "owner") {
            _.mapValues(row[type], function(o) {
              if (o.fullName === newValue) {
                o.selected = true
              } else if (newValue === "Both") {
                if (_.has(o, "selected")) o.selected = true
              } else if (newValue ==="Other") {
                if (_.has(o, "selected")) o.selected = false
              } else {
                if (_.has(o, "selected")) o.selected = false
              }
            })
          } else {
            row[type] = newValue
          }
        }
      })
    }
  })
}

// ========================== REST API =================
export const saveData = async (props: i_ACStates | any | undefined) => {
  const {
    unsecuredLiabilities,
    nonFinancialAssets,
    versionControlId,
    financialAssets,
    client1Income,
    client2Income,
    rentalIncome,
    interestRate,
    stressBuffer,
    termsInYears,
    deductions,
    creditCard,
    homeLoans,
    expenses,
    notes,
    title,
  } = props

  if (!_.isNil(client1Income)) {
    delete client1Income.totalIncomeBreakDown
    delete client1Income.totalYTDCalculator
  }
  if (!_.isNil(client2Income)) {
    delete client2Income.totalIncomeBreakDown
    delete client2Income.totalYTDCalculator
  }

  const response = await _fetch("client/addMortgageCalcData", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      unsecuredLiabilities,
      nonFinancialAssets,
      versionControlId,
      financialAssets,
      client1Income,
      client2Income,
      rentalIncome,
      interestRate,
      stressBuffer,
      termsInYears,
      deductions,
      creditCard,
      homeLoans,
      expenses,
      notes,
      title,
    }),
  })
  return await response.json()
}

export const getTotal = (rows: any) => {
  let ret: any = { total: [], parentTotal: [] }
  if (rows) {
    rows.forEach((row: any) => {
      const indexOfComputed = row.map((x: any, index: any) => {
        if (x.isComputed) {
          return index
        }
      })
      const indexOfForTotal = row
        .map((x: any, index: any) => {
          if (x.isForTotal) {
            return index
          }
        })
        .filter(Boolean)

      indexOfComputed.map((index: any) => {
        if (index > 0) {
          ret.total[index] =
            ret.total[index] +
              parseFloat(
                row[index].val && row[index].val.toString().replace(/,/g, "")
              ) ||
            parseFloat(
              row[index].val && row[index].val.toString().replace(/,/g, "")
            )
        }
      })
      indexOfForTotal.map((index: any) => {
        if (index > 0) {
          ret.parentTotal[index] =
            ret.parentTotal[index] +
              parseFloat(
                row[index].val && row[index].val.toString().replace(/,/g, "")
              ) ||
            parseFloat(
              row[index].val && row[index].val.toString().replace(/,/g, "")
            )
        }
      })
    })
  }
  return ret
}

export const getMonthlyFrequencyMultiplier = (frequency: String) => {
  switch (frequency) {
    case "Weekly":
      return 52 / 12
    case "Fortnightly":
      return 26 / 12
    case "Monthly":
      return 1
    case "Every 2 months":
      return 1 / 2
    case "Every 3 months":
      return 1 / 3
    case "Every 6 months":
      return 1 / 6
    case "Yearly":
      return 1 / 12
    case "Every 2 years":
      return 1 / 24
    case "Quarterly":
      return 1 / 3
    case "Bi-Monthly":
      return 24 / 12
    default:
      return 0
  }
}
