import { Grid } from "@material-ui/core"
import _ from "lodash"
import ListItem from "@material-ui/core/ListItem"
import React, { ReactElement, useState, useEffect } from "react"
import FileItem from "../../../components/FileItem/FileItem"
import adminStyles from "../AdminRequest/AdminRequest.module.scss"
import { Document } from "./RequestHistory.interfaces"
import styles from "./RequestHistory.module.scss"
import { _fetch } from "../../../lib/utils"

const RequestHistoryItem = ({
  documents,
  status,
  refresh,
  clientId,
  transactionId,
  checkDownloadHandler,
  resetComponent,
  setResetItemComponent,
}: {
  documents: ReadonlyArray<Document>
  status: string
  refresh: any
  clientId: any
  transactionId: String
  checkDownloadHandler: Function
  resetComponent: any //Boolean|boolean doesn't work, seems to prompt an error.
  setResetItemComponent: Function
}): ReactElement => {
  const [isRemovedChecked, setRemovedChecked] = useState(false)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<
    ReadonlyArray<string>
  >([])
  const groupedDocuments = _.chain(documents)
    .groupBy("dcid.documentType")
    .map((value, key) => ({ documentType: key, documents: value }))
    .value()

  const [filesInAGroup, setFilesInAGroup] = useState(
    documents.map(doc => doc.files?.length || false).find(Boolean)
  )
  const uncheckAll = () => {
    setSelectedDocuments([])
    setRemovedChecked(false)
    setResetItemComponent(false)
    setIsCheckedAll(false)
  }

  useEffect(() => {
    if (resetComponent) {
      uncheckAll()
    }
  }, [resetComponent])

  useEffect(() => {
    if (isRemovedChecked) {
      uncheckAll()
    }
    setRemovedChecked(resetComponent)
  }, [resetComponent, isRemovedChecked, checkDownloadHandler])

  const onCheckAllHandler = () => {
    const newValue = !isCheckedAll
    setIsCheckedAll(newValue)
    let arrFiles: any = []
    if (newValue) {
      let docIds: any = []
      groupedDocuments.forEach(({ documents }) => {
        documents.forEach(({ _id }) => {
          docIds = [...docIds, _id]
        })
      })
      setSelectedDocuments(docIds)
    } else {
      setSelectedDocuments([])
    }
    groupedDocuments.forEach(({ documents }) => {
      // This implementation strongly needs a nested loop using forEach
      // instead of map because of the data structure.

      documents.forEach((doc: any) => {
        doc.files.forEach((i: any) => {
          arrFiles.push({
            AWSFileKey: ["DocumentCollection", clientId, i.file_name].join("/"),
            checked: newValue,
            createdAt: i.createdAt,
            documentType: "Assets",
            fileLabel: i.label,
            _id: i._id,
          })
        })
      })
    })

    checkDownloadHandler(arrFiles)
  }

  const renderFileItem = (args: any) => {
    const params = {
      _id: args._id,
      item: args.item,
      files: args.files,
      notes: args.notes,
      display: {
        view: true,
        isShowNote: true,
        isShowCheckbox: true,
        isShowIconEdit: false,
        download: true,
        isShowIconFolder: false,
        isShowIconDelete: false,
        isShowDateCreated: false,
        isShowDateUpdated: false,
        isShowStatus:
          status === "not-completed" && args.files && args.files.length === 0
            ? false
            : true,
        ...args.display,
      },
    }

    return (
      <ListItem className={styles.fileItemContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FileItem
              item={params.item}
              onFileUploaderModalOpen={() => {}}
              refreshRequestedDocuments={() => {}}
              clientId={clientId}
              isFromDocument
              folder="Mortgages"
              display={params.display}
              notes={params.notes}
              files={params.files}
              onCheckedChange={checked => {
                if (checked) {
                  setSelectedDocuments([...selectedDocuments, params._id])
                } else {
                  setSelectedDocuments(
                    selectedDocuments.filter(
                      (docId: string) => docId !== params._id
                    )
                  )
                  setIsCheckedAll(false)
                }
              }}
              isChecked={selectedDocuments.includes(params._id)}
              isReadOnlyNotes={true}
              checkDownloadHandler={checkDownloadHandler}
            />
          </Grid>
        </Grid>
      </ListItem>
    )
  }

  const requestHistoryItem = groupedDocuments.map(
    ({ documentType, documents }) => {
      const requestedDocs = documents.map(({ dcid, _id, notes, files }) => {
        if (files && files.length > 1) {
          // Multi File Item will have children fileitems
          return (
            <>
              {// parent file item, no download and view buttons
              renderFileItem({
                _id,
                files,
                notes,
                item: { ...dcid, _id, transactionId },
                display: { download: false, view: false },
              })}
              {
                // children file item, no note, status and checkbox
                <div className={styles.childFileItem}>
                  {files.map((i: any) =>
                    renderFileItem({
                      _id,
                      files,
                      item: i,
                      display: {
                        isShowNote: false,
                        isShowStatus: false,
                        isShowCheckbox: false,
                      },
                    })
                  )}
                </div>
              }
            </>
          )
        } else {
          // Single File Item
          const ONLY_ITEM_KEY = 0
          const singleItem = (files && files[ONLY_ITEM_KEY]) || {}
          return renderFileItem({
            _id,
            files,
            notes,
            item: {
              ...dcid,
              _id,
              transactionId,
              ...singleItem,
            },
          })
        }
      })

      return (
        <>
          <ListItem component="a" className={adminStyles.listDocumentType}>
            <span className={styles.ml3}>{documentType}</span>
          </ListItem>
          {requestedDocs}
        </>
      )
    }
  )

  return (
    <>
      <ListItem>
        <div style={{ padding: "9px 0" }}>
          {filesInAGroup && (
            <label className={styles.label}>
              <input
                className={styles.inputCheckbox}
                type="checkbox"
                checked={isCheckedAll}
                onClick={onCheckAllHandler}
              />{" "}
              Select All
            </label>
          )}
        </div>
      </ListItem>
      {requestHistoryItem}
    </>
  )
}
export default RequestHistoryItem
