import React from "react";
import Grid from "@material-ui/core/Grid";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import styles from "./NavBar.module.scss";
interface i_NavBar {
  currentPage: String;
  setCurrentPage: Function;
  pages: Array<any>;
}
const NavBar = ({ currentPage, setCurrentPage, pages }: i_NavBar) => {
  return (
    <div className={styles.navbarContainer}>
      <div className={styles.root1}>
        <Grid container>
          {/* <Grid
            item
            xs={3}
            sm={3}
            md={1}
            lg={1}
            className={styles.backBtn}
            onClick={() => window.history.back()}
          >
            <ArrowBackIosIcon className={styles.backBtnIcon} />
            <p className={styles.backBtnText}>Back</p>
          </Grid> */}
          <Grid item xs={9} sm={9} md={11} lg={11}>
            <h3
              className={styles.title}
              style={{
                color: "var(--grey-color-2)",
              }}
            >
              My Documents
            </h3>
          </Grid>
        </Grid>
      </div>
      <div className={styles.root2}>
        <Grid container>
          <Grid item xs={12}>
            <ul>
              {pages.map((page) =>
                page.show ? (
                  <li
                    className={currentPage === page.name ? styles.active : ""}
                    onClick={() => setCurrentPage(page.name)}
                  >
                    {page.label}
                  </li>
                ) : null
              )}
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NavBar;
