exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n.O-NeYGH4WVSYiRhjbHrJJ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n.Db_yhwmEhauMeIX3N4wya {\n  padding-bottom: 5px; }\n\n._3U6kJentA8p5XZQuamQNqz {\n  min-width: 40px !important; }\n\n._2SPLBcH0DPRDt1GeYhcevC {\n  padding-right: 20px !important; }\n\n._3P9kV8gPqtQmplZ4_DI8So {\n  margin-right: 20px; }\n\n._3JR-UJhH08m7qwM5BhN-Kj {\n  color: var(--primary-color) !important;\n  font-size: 40px !important; }\n\n.pw--Rj0wNU0MjnWJBFr-J {\n  color: var(--primary-color) !important;\n  font-size: 30px !important; }\n\n._12FExITP5oErLBlQTgCJST {\n  background-color: #ccc;\n  padding: 10px;\n  display: flex;\n  color: var(--primary-color);\n  align-items: center;\n  justify-content: space-evenly;\n  font-size: 18px;\n  font-weight: 400;\n  border-radius: 3px;\n  padding: calc(1rem * 1.8) calc(1rem * 1.8) calc(1rem * 1.8);\n  background-color: #e9e9e9; }\n\n._2BBTzlP3SCVsKc2so_PQlj {\n  background-color: #ccc;\n  margin-left: auto; }\n\n.fa83FerrTUzoBiHrSP-al {\n  flex: 2; }\n\n._3p8swEYP31qpI-tB7IhHNo {\n  display: flex;\n  flex-direction: column;\n  font-size: 13px;\n  color: #333;\n  border-left: 1px solid #999;\n  padding: 5px 15px; }\n  ._3p8swEYP31qpI-tB7IhHNo span {\n    color: var(--primary-color); }\n\n.RBkTIJI0l7lpHLsemN8Ll {\n  font-weight: bold;\n  color: var(--primary-color); }\n\n._1PPOpV3nKjrKmxMQl7De80 {\n  border-radius: 2.5px;\n  border: 0.06rem solid #353535;\n  font-size: 13px;\n  font-weight: 400;\n  color: #353535;\n  vertical-align: middle;\n  text-align: center;\n  width: 8.5rem;\n  height: 3rem;\n  text-transform: none;\n  display: inline-block;\n  line-height: 3rem; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "O-NeYGH4WVSYiRhjbHrJJ",
	"container": "Db_yhwmEhauMeIX3N4wya",
	"icon": "_3U6kJentA8p5XZQuamQNqz",
	"icontext": "_2SPLBcH0DPRDt1GeYhcevC",
	"optionsMenu": "_3P9kV8gPqtQmplZ4_DI8So",
	"addIcon": "_3JR-UJhH08m7qwM5BhN-Kj",
	"menuIcon": "pw--Rj0wNU0MjnWJBFr-J",
	"itemContainer": "_12FExITP5oErLBlQTgCJST",
	"space": "_2BBTzlP3SCVsKc2so_PQlj",
	"item": "fa83FerrTUzoBiHrSP-al",
	"itemDate": "_3p8swEYP31qpI-tB7IhHNo",
	"textBold": "RBkTIJI0l7lpHLsemN8Ll",
	"status": "_1PPOpV3nKjrKmxMQl7De80"
};