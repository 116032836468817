import MultiDataTable from "../../../../components/MultiDataTable/MultiDataTable";
import EditableText from "../../../../components/EditableText/EditableText";
import Modal from "../../../../components/Modal";
import {
  formatMoney,
  getBorrowingPower,
  getDTI,
  computeEstimatedTaxPaid,
  deductedEstimatedTaxPaid,
} from "./calculations";
import Accordion from "../../../../components/Accordion/Accordion";
import DataTable from "../../../../components/DataTable/DataTable";
import {
  i_AssessmentCalculator,
  i_ACStates,
  i_rentalAddition,
  i_rentalUpdates,
  i_unsecuredAdditional,
  i_unsecuredUpdates,
  i_assetChanges,
  i_incomeChanges,
  i_FinancialAssets,
  i_RentalIncome,
  i_UnsecuredLiabilities,
  i_IncomeBreakDown,
  i_HomeLoans,
  i_CreditCard,
  i_homeLoansUpdates,
  i_homeLoansAddition,
  i_creditUpdates,
  i_flexItemsUpdates,
} from "./interface";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DoneIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import React, { useState, useEffect, useContext } from "react";
import { _fetch } from "../../../../lib/utils";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { FadeLoader } from "react-spinners";
import styles from "./styles.scss";
import moment from "moment";
import _ from "lodash";
import {
  generateUnsecuredLiabilitiesRow,
  getMonthlyFrequencyMultiplier,
  generateNonFinancialAssetsRow,
  generateFinancialAssetsRow,
  generateRentalIncomeRow,
  generateCreditCardRow,
  generateDeductionRow,
  generateHomeLoansRow,
  generateExpensesRow,
  generateYTDCalcRow,
  generateIncomeRow,
  handleUpdateRow,
  handleAddRow,
  saveData,
  getTotal,
} from "./utils";
import {
  FINANCIAL_ASSETS_CLIENT1_INDEX,
  FINANCIAL_ASSETS_CLIENT2_INDEX,
  FINANCIAL_ASSETS_TOTAL_KEY,
  RENTAL_INCOME_CLIENT1_INDEX,
  RENTAL_INCOME_CLIENT2_INDEX,
  PROPOSED_CREDIT_CARD_INDEX,
  OUTSTANDING_BALANCE_INDEX,
  CREDIT_CARD_LIMIT_INDEX,
  MONTHLY_REPAYMENT_INDEX,
  RENTAL_INCOME_INDEX,
  UnsecuredLiabilitiesColumns,
  NonFinancialAssetsColumns,
  FinancialAssetsColumns,
  RentalIncomeColumns,
  DeductionsColumns,
  CreditCardColumns,
  HomeLoansColumns,
  ExpensesColumns,
  YTDCalcColumns,
  IncomeColumns,
  FINANCIAL_ASSETS_BALANCE_KEY,
  NON_FINANCIAL_ASSETS_BALANCE_KEY,
  NON_FINANCIAL_ASSETS_TOTAL_KEY,
} from "./config";
import {
  checkObjectPropsLength,
  clientChangesRentalAdditions,
  clientChangesRentalIncomeUpdate,
  clientChangesAssetsAdditions,
  clientChangesAssetsUpdate,
  clientChangesIncomeUpdate,
  clientChangesIncomeAdditional,
  clientChangesUnsecuredUpdates,
  clientChangesUnsecuredAddition,
  clientChangesHomeLoansUpdate,
  clientHomeLoansAdditions,
  clientChangesCreditUpdates,
  clientChangesCreditAddition,
  clientChangesFlexExpenseUpdates,
  flexExpenseHasDifferences,
  arrangeFlexExpenseOrder,
} from "../../../../lib/utils";
import Portal from "../../../../components/Portal/Portal";
import ClientChangesContext from "../../../../components/Context/ClientChangesContext";
import ClientChangesModal from "./ClientChanges/ClientChangesModal";
import VersionAssessmentContext from "../../../../components/Context/VersionAssessmentContext";

const AssessmentCalculator: React.FC<i_AssessmentCalculator> = (props) => {
  // ===[ Destructuring Props / Default Datas ] =============================================
  const addPreviousYearIncomeRow = (obj: any) => {
    if (obj.filter((i: any) => "previousYearIncome" in i).length === 0) {
      obj.push({ previousYearIncome: 0 });
    }
    return obj;
  };

  const { isCompleted } = useContext(VersionAssessmentContext);

  const {
    incomeBreakdownClient1,
    incomeBreakdownClient2,
    allNonFinancialAssets,
    unsecuredLiabilities,
    allFinancialAssets,
    rentalIncome,
    flexExpenses,
    creditCard,
    homeLoans,
  } = props;

  const portalDiv = document.getElementById("react-wrapper");

  const defaultData = {
    client1Income: {
      incomeBreakdown: addPreviousYearIncomeRow(incomeBreakdownClient1),
      totalYTDCalculator: 0,
      ytdCalculator: [
        {
          startDate: moment(),
          finishDate: moment(),
          ytdPay: 0,
          incomeType: "",
        },
      ],
    },
    client2Income: {
      incomeBreakdown: addPreviousYearIncomeRow(incomeBreakdownClient2),
      totalYTDCalculator: 0,
      ytdCalculator: [
        {
          startDate: moment(),
          finishDate: moment(),
          ytdPay: 0,
          incomeType: "",
        },
      ],
    },
    deductions: [],
    nonFinancialAssets: allNonFinancialAssets,
    financialAssets: allFinancialAssets,
    expenses: arrangeFlexExpenseOrder(flexExpenses)?.map((exp) => ({
      ...exp,
      isOpen: false,
    })),
    unsecuredLiabilities,
    rentalIncome,
    creditCard,
    homeLoans,
  };

  // ===[ State/ Variable Declarations ]======================================

  const [showIncomeBreakDownDetails, setShowIncomeBreakDownDetails] =
    useState(false);
  const [overallIncomeBreakDown, setOverallIncomeBreakDown] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [ACStates, setACStates] = useState<i_ACStates | undefined>(undefined);
  const [versionControlId, setVersionControlId] = useState<String>("");
  const [totalForHomeLoans, setTotalForHomeLoans] = useState<any>([]);
  const [ALCTotal, setALCTotal] = useState<any>({ dti: 0, alc: 0 });
  const [accordionTotal, setAccordionTotal] = useState<any>({
    unsecuredLiabilities: 0,
    nonFinancialAssets: [0],
    financialAssets: [0, 0, 0, 0],
    creditCard: [0, 0],
    homeLoans: [0, 0],
    client1Income: 0,
    client2Income: 0,
    rentalIncome: 0,
    deductions: 0,
    expenses: 0,
  });

  const [showPortal, setShowPortal] = useState(false);
  const [rentalUpdates, setRentalUpdates] = useState<i_rentalUpdates>({
    addressChanges: [],
    ownership1Changes: [],
    ownership2Changes: [],
    propertyValueChanges: [],
    rentChanges: [],
  });
  const [rentalAddition, setRentalAddition] = useState<i_rentalAddition>({
    addressChanges: [],
    ownership1Changes: [],
    ownership2Changes: [],
    propertyValueChanges: [],
    rentChanges: [],
  });
  const [financialAssetUpdates, setFinancialAssetUpdates] =
    useState<i_assetChanges>({
      typeChanges: [],
      balanceChanges: [],
      ownerChanges: [],
    });
  const [financialAssetAddition, setFinancialAssetAddition] =
    useState<i_assetChanges>({
      typeChanges: [],
      balanceChanges: [],
      ownerChanges: [],
    });
  const [nonFinancialAssetUpdates, setNonFinancialAssetUpdates] =
    useState<i_assetChanges>({
      typeChanges: [],
      balanceChanges: [],
      ownerChanges: [],
    });
  const [nonFinancialAssetAddition, setNonFinancialAssetAddition] =
    useState<i_assetChanges>({
      typeChanges: [],
      balanceChanges: [],
      ownerChanges: [],
    });
  const [income1Updates, setIncome1Updates] = useState<i_incomeChanges>({
    portalChanges: [],
    typeChanges: [],
  });
  const [income1Addition, setIncome1Addition] = useState<i_incomeChanges>({
    portalChanges: [],
    typeChanges: [],
  });

  const [income2Updates, setIncome2Updates] = useState<i_incomeChanges>({
    portalChanges: [],
    typeChanges: [],
  });
  const [income2Addition, setIncome2Addition] = useState<i_incomeChanges>({
    portalChanges: [],
    typeChanges: [],
  });

  const [unsecuredUpdates, setUnsecuredUpdates] = useState<i_unsecuredUpdates>({
    balanceChanges: [],
    ownerChanges: [],
    periodChanges: [],
    repaymentChanges: [],
    typeChanges: [],
  });

  const [unsecuredAdditional, setUnsecuredAdditional] =
    useState<i_unsecuredAdditional>({
      balanceChanges: [],
      ownerChanges: [],
      periodChanges: [],
      repaymentChanges: [],
      typeChanges: [],
    });
  const [homeLoansUpdates, setHomeLoansUpdates] = useState<i_homeLoansUpdates>({
    lenderChanges: [],
    loanTypeChanges: [],
    primaryPurposeChanges: [],
    borrowerChanges: [],
    repaymentTypeChanges: [],
    repaymentChanges: [],
    periodChanges: [],
    loanExpiryChanges: [],
    balanceChanges: [],
    accountNumberChanges: [],
    ioExpChanges: [],
    fixedIOChanges: [],
    addressChanges: [],
    baseRateChanges: [],
    discountRateChanges: [],
    interestRateChanges: [],
  });
  const [homeLoansAddition, setHomeLoansAddition] =
    useState<i_homeLoansAddition>({
      lenderChanges: [],
      loanTypeChanges: [],
      primaryPurposeChanges: [],
      borrowerChanges: [],
      repaymentTypeChanges: [],
      repaymentChanges: [],
      periodChanges: [],
      loanExpiryChanges: [],
      balanceChanges: [],
      accountNumberChanges: [],
      ioExpChanges: [],
      fixedIOChanges: [],
      addressChanges: [],
      baseRateChanges: [],
      discountRateChanges: [],
      interestRateChanges: [],
    });

  const [creditUpdates, setCreditUpdates] = useState<i_creditUpdates>({
    lenderChanges: [],
    limitChanges: [],
    ownerChanges: [],
    proposedLimitChanges: [],
  });

  const [creditAddition, setCreditAddition] = useState<i_creditUpdates>({
    lenderChanges: [],
    limitChanges: [],
    ownerChanges: [],
    proposedLimitChanges: [],
  });

  const [flexExpenseUpdates, setFlexExpenseUpdates] =
    useState<i_flexItemsUpdates>({
      rentChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        rentChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      utilitiesAndRates: {
        flexType: "",
        monthlyTotalAmount: 0,
        utilitiesRatesChanges: [],
        prevMonthlyTotalAmount: 0,
      },
      insuranceChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        insuranceChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      telephoneInternetChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        telephoneChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      transportChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        transportChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      educationChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        educationChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      childcareChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        childcareChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      otherLivingChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        otherLivingChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      groceriesChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        groceriesChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      clothingChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        clothingChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      medicalChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        medicalChangesArr: [],
        prevMonthlyTotalAmount: 0,
      },
      recreationChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        prevMonthlyTotalAmount: 0,
        recreationChangesArr: [],
      },
      utilitiesInvestmentChanges: {
        flexType: "",
        monthlyTotalAmount: 0,
        prevMonthlyTotalAmount: 0,
        utilitiesInvestmentChangesArr: [],
      },
    });

  const [showFlexExpenseTitle, setShowFlexExpenseTitle] = useState(false);
  const [completeSuccess, setCompleteSuccess] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalValue, setAlertModalValue] = useState(<></>);

  // ===[ Functions ]=========================================================

  const handleUpdate = (
    table: String,
    item: any,
    id: Number,
    newValue: any
  ) => {
    const updateState = { ...ACStates } as i_ACStates;
    let str: any;
    if (table.includes("incomeBreakdown") || table.includes("ytdCalculator")) {
      str = table.split(".")[1];
      table = table.split(".")[0];
    }
    Object.keys(updateState!).forEach((key) => {
      if (key === table) {
        if (key === "client1Income" || key === "client2Income") {
          const fk: keyof i_ACStates = key as keyof i_ACStates;
          if (str === "incomeBreakdown") {
            const data = updateState![fk].incomeBreakdown;
            handleUpdateRow({ data, item, id, newValue });
          } else if (str === "ytdCalculator") {
            const data = updateState![fk].ytdCalculator;
            handleUpdateRow({ data, item, id, newValue });
          }
        } else {
          const fk: keyof i_ACStates = key as keyof i_ACStates;
          const data = updateState![fk];
          handleUpdateRow({ data, item, id, newValue });
        }
      }
    });
    setACStates(updateState);
  };
  const getACData = async (
    rentalIncome: i_RentalIncome[] | undefined,
    allFinancialAssets: i_FinancialAssets[] | undefined,
    allNonFinancialAssets: i_FinancialAssets[] | undefined,
    incomeBreakdownClient1: i_IncomeBreakDown[] | undefined,
    incomeBreakdownClient2: i_IncomeBreakDown[] | undefined,
    unsecuredLiabilities: i_UnsecuredLiabilities[] | undefined,
    homeLoans: i_HomeLoans[] | undefined,
    creditCard: i_CreditCard[] | undefined,
    passFlexExpense: any
  ) => {
    setIsLoading(true);

    if (props.id) {
      // Edit
      const response = await _fetch(
        "client/getMortgageCalc/" + props.uid + "/" + props.id,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const json = await response.json();
      const fetchedRentalIncome = await json.data.rentalIncome.map(
        (rental: { _id: string }) => {
          delete rental._id;
          return rental;
        }
      );
      const fetchedClient1Income = await json.data.client1Income.incomeBreakdown
        .map((income: { _id: any }) => {
          delete income._id;
          return income;
        })
        .filter((income: { summaryId: string }) => income.summaryId !== "");

      const fetchedClient2Income = await json.data.client2Income.incomeBreakdown
        .map((income: { _id: string }) => {
          delete income._id;
          return income;
        })
        .filter((income: { summaryId: string }) => income.summaryId !== "");

      const fetchedHomeLoans = await json.data.homeLoans.map(
        (homeLoans: { _id: any }) => {
          delete homeLoans._id;
          return homeLoans;
        }
      );

      const isRentalIncomeEqual = _.isEqual(fetchedRentalIncome, rentalIncome);
      const isClient1IncomeEqual = _.isEqual(
        fetchedClient1Income,
        incomeBreakdownClient1
      );
      const isClient2IncomeEqual = _.isEqual(
        fetchedClient2Income,
        incomeBreakdownClient2
      );
      const isHomeLoansEqual = _.isEqual(fetchedHomeLoans, homeLoans);
      const rentalPrevCurr = clientChangesRentalIncomeUpdate(
        rentalIncome,
        fetchedRentalIncome
      );
      const income1Updates = clientChangesIncomeUpdate(
        incomeBreakdownClient1,
        fetchedClient1Income
      );

      const income1Additional = clientChangesIncomeAdditional(
        incomeBreakdownClient1,
        fetchedClient1Income
      );

      const income2Updates = clientChangesIncomeUpdate(
        incomeBreakdownClient2,
        fetchedClient2Income
      );
      const income2Additional = clientChangesIncomeAdditional(
        incomeBreakdownClient2,
        fetchedClient2Income
      );
      const rentalAdditonal = clientChangesRentalAdditions(
        rentalIncome,
        fetchedRentalIncome
      );
      const checkRentalUpdates: boolean =
        checkObjectPropsLength(rentalPrevCurr);
      const checkRentalAddition: boolean =
        checkObjectPropsLength(rentalAdditonal);

      const checkIncome1Updates: boolean =
        checkObjectPropsLength(income1Updates);
      const checkIncome1Additional: boolean =
        checkObjectPropsLength(income1Additional);
      const checkIncome2Updates: boolean =
        checkObjectPropsLength(income2Updates);
      const checkIncome2Additional: boolean =
        checkObjectPropsLength(income2Additional);

      if (!isRentalIncomeEqual && (checkRentalAddition || checkRentalUpdates)) {
        setShowPortal(true);
        setRentalUpdates(rentalPrevCurr);
        setRentalAddition(rentalAdditonal);
      }

      const fetchedFinancialAssets = await json.data.financialAssets.map(
        (asset: { _id: string }) => {
          delete asset._id;
          return asset;
        }
      );

      const isFinancialAssetEqual = _.isEqual(
        fetchedFinancialAssets,
        allFinancialAssets
      );
      const finAssetPrevCurr = clientChangesAssetsUpdate(
        allFinancialAssets,
        fetchedFinancialAssets
      );
      const finAssetAdditonal = clientChangesAssetsAdditions(
        allFinancialAssets,
        fetchedFinancialAssets
      );
      const checkFinancialAssetUpdates =
        checkObjectPropsLength(finAssetPrevCurr);
      const checkFinancialAssetAddition =
        checkObjectPropsLength(finAssetAdditonal);

      if (
        !isFinancialAssetEqual &&
        (checkFinancialAssetAddition || checkFinancialAssetUpdates)
      ) {
        setShowPortal(true);
        setFinancialAssetUpdates(finAssetPrevCurr);
        setFinancialAssetAddition(finAssetAdditonal);
      }

      const fetchedNonFinancialAssets = await json.data.nonFinancialAssets.map(
        (asset: { _id: string }) => {
          delete asset._id;
          return asset;
        }
      );

      const isNonFinancialAssetEqual = _.isEqual(
        fetchedNonFinancialAssets,
        allNonFinancialAssets
      );
      const nonFinAssetPrevCurr = clientChangesAssetsUpdate(
        allNonFinancialAssets,
        fetchedNonFinancialAssets
      );
      const nonFinAssetAdditonal = clientChangesAssetsAdditions(
        allNonFinancialAssets,
        fetchedNonFinancialAssets
      );
      const checkNonFinancialAssetUpdates: boolean =
        checkObjectPropsLength(nonFinAssetPrevCurr);
      const checkNonFinancialAssetAddition: boolean =
        checkObjectPropsLength(nonFinAssetAdditonal);
      if (
        !isNonFinancialAssetEqual &&
        (checkNonFinancialAssetAddition || checkNonFinancialAssetUpdates)
      ) {
        setShowPortal(true);
        setNonFinancialAssetUpdates(nonFinAssetPrevCurr);
        setNonFinancialAssetAddition(nonFinAssetAdditonal);
      }

      const fetchedUnsecuredLiabilities =
        await json.data.unsecuredLiabilities.map((item: { _id: string }) => {
          delete item._id;
          return item;
        });

      const isUnsecuredEqual = _.isEqual(
        fetchedUnsecuredLiabilities,
        unsecuredLiabilities
      );

      const unsecuredUpdates = clientChangesUnsecuredUpdates(
        unsecuredLiabilities,
        fetchedUnsecuredLiabilities
      );
      const unsecuredAdditional = clientChangesUnsecuredAddition(
        unsecuredLiabilities,
        fetchedUnsecuredLiabilities
      );
      const checkUnsecuredUpdates: boolean =
        checkObjectPropsLength(unsecuredUpdates);
      const checkUnsecuredAdditional: boolean =
        checkObjectPropsLength(unsecuredAdditional);

      if (
        !isUnsecuredEqual &&
        (checkUnsecuredUpdates || checkUnsecuredAdditional)
      ) {
        setShowPortal(true);
        setUnsecuredAdditional(unsecuredAdditional);
        setUnsecuredUpdates(unsecuredUpdates);
      }

      const fetchedCreditCard = await json.data.creditCard.map(
        (credit: { _id: string }) => {
          delete credit._id;
          return credit;
        }
      );

      const isCreditEqual = _.isEqual(creditCard, fetchedCreditCard);

      const creditCardUpdates = clientChangesCreditUpdates(
        creditCard,
        fetchedCreditCard
      );

      const creditCardAddition = clientChangesCreditAddition(
        creditCard,
        fetchedCreditCard
      );

      const checkCreditCardUpdates: boolean =
        checkObjectPropsLength(creditCardUpdates);
      const checkCreditCardAddition: boolean =
        checkObjectPropsLength(creditCardAddition);

      if (
        !isCreditEqual &&
        (checkCreditCardAddition || checkCreditCardUpdates)
      ) {
        setShowPortal(true);
        setCreditUpdates(creditCardUpdates);
        setCreditAddition(creditCardAddition);
      }

      if (
        !isClient1IncomeEqual &&
        (checkIncome1Additional || checkIncome1Updates)
      ) {
        setShowPortal(true);
        setIncome1Updates(income1Updates);
        setIncome1Addition(income1Additional);
      }

      if (
        !isClient2IncomeEqual &&
        (checkIncome2Updates || checkIncome2Additional)
      ) {
        setShowPortal(true);
        setIncome2Updates(income2Updates);
        setIncome2Addition(income2Additional);
      }
      const homeLoansPrevCurr = clientChangesHomeLoansUpdate(
        homeLoans,
        fetchedHomeLoans
      );
      const homeLoansAdditional = clientHomeLoansAdditions(
        homeLoans,
        fetchedHomeLoans
      );
      const checkHomeLoansUpdates = checkObjectPropsLength(homeLoansPrevCurr);
      const checkHomeLoansAddition =
        checkObjectPropsLength(homeLoansAdditional);

      if (
        !isHomeLoansEqual &&
        (checkHomeLoansAddition || checkHomeLoansUpdates)
      ) {
        setShowPortal(true);
        setHomeLoansUpdates(homeLoansPrevCurr);
        setHomeLoansAddition(homeLoansAdditional);
      }

      const fetchedExpenses = await json.data.expenses.map(
        (expense: {
          items: any[];
          flexType: string;
          monthlyAmount: number;
        }) => {
          const { flexType, monthlyAmount } = expense;
          const expenseItems = expense.items.map((item) => {
            delete item._id;
            return item;
          });
          return {
            flexType,
            monthlyAmount,
            items: expenseItems,
          };
        }
      );

      const isFlexExpenseEqual = _.isEqual(fetchedExpenses, passFlexExpense);

      const checkFlexExpenseUpdates = clientChangesFlexExpenseUpdates(
        fetchedExpenses,
        passFlexExpense
      );

      const flexExpenseDifferences = flexExpenseHasDifferences(
        checkFlexExpenseUpdates
      );

      if (!isFlexExpenseEqual && flexExpenseDifferences) {
        setShowFlexExpenseTitle(true);
        setShowPortal(true);
        setFlexExpenseUpdates(checkFlexExpenseUpdates);
      }

      const ACDatas = json.data;
      setACStates({
        ...ACDatas,
        expenses: [
          ...ACDatas.expenses.map((e: any) => ({
            ...e,
            isOpen: false,
          })),
        ],
      });
      accordionTotalHandler(json.data);
      setVersionControlId(props.id);
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
    } else {
      setACStates({
        termsInYears: 0,
        stressBuffer: 0,
        interestRate: 0,
        ...defaultData,
      });

      const saveDataMount = {
        termsInYears: 0,
        stressBuffer: 0,
        interestRate: 0,
        ...defaultData,
      };
      const saveAction = await saveData(saveDataMount);

      if (saveAction.success) {
        setVersionControlId(
          !_.isNil(saveAction.versionControlId)
            ? saveAction.versionControlId.toString()
            : ""
        );
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
      } else {
        setAlertModalValue(
          <div className={styles.alertModalContent}>
            <h3>Something's Wrong</h3>
            <p>{saveAction.message.message}</p>
            <hr />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Go back
            </Button>
          </div>
        );
        setShowAlertModal(true);

        // alert('Something is wrong: \n' + saveAction.message.message + '\nReloading your page now...')
      }
    }
  };

  const computeTotalForHomeLoans = (data: any) => {
    let homeLoansTotal = [, 0, , 0];
    let grandTotal = 0;
    let grandbalance = 0;
    data.homeLoans.map((hl: i_HomeLoans) => {
      if (!hl.isRefi) {
        grandbalance += hl.balance;
        grandTotal += hl.repayment * getMonthlyFrequencyMultiplier(hl.period);
      }
    });

    homeLoansTotal[1] = grandbalance;
    homeLoansTotal[3] = grandTotal;

    return homeLoansTotal;
  };

  const accordionTotalHandler = (data: i_ACStates) => {
    const initialClient1Income = getTotal(
      generateIncomeRow(data?.client1Income?.incomeBreakdown)
    ).parentTotal.filter(Boolean);
    const initialClient2Income = getTotal(
      generateIncomeRow(data?.client2Income?.incomeBreakdown)
    ).parentTotal.filter(Boolean);
    const initialDeductions = getTotal(
      generateDeductionRow(data?.deductions)
    ).parentTotal.filter(Boolean);
    const initialRentalIncome = getTotal(
      generateRentalIncomeRow(data?.rentalIncome)
    ).parentTotal.filter(Boolean);
    const initialHomeLoans =
      computeTotalForHomeLoans(data) &&
      computeTotalForHomeLoans(data).filter((i: any) => _.isNumber(i));
    setTotalForHomeLoans(computeTotalForHomeLoans(data));
    const initialUnsecuredLiabilities = getTotal(
      generateUnsecuredLiabilitiesRow(data?.unsecuredLiabilities)
    ).parentTotal.filter(Boolean);
    const initialCreditCard = getTotal(
      generateCreditCardRow(data?.creditCard)
    ).parentTotal.filter((i: any) => _.isNumber(i));
    const initialFinancialAssets = getTotal(
      generateFinancialAssetsRow(data?.financialAssets)
    ).parentTotal.filter((i: any) => _.isNumber(i));
    const initialNonFinancialAssets = getTotal(
      generateNonFinancialAssetsRow(data?.nonFinancialAssets)
    ).parentTotal.filter((i: any) => _.isNumber(i));
    const initialExpenses = getTotal(
      generateExpensesRow(data?.expenses)
    ).parentTotal.filter(Boolean);

    const updateState = { ...accordionTotal };
    updateState.client1Income =
      initialClient1Income.length !== 0 ? initialClient1Income : 0;
    updateState.client2Income =
      initialClient2Income.length !== 0 ? initialClient2Income : 0;
    updateState.deductions =
      initialDeductions.length !== 0 ? initialDeductions : 0;
    updateState.rentalIncome =
      initialRentalIncome.length !== 0 ? initialRentalIncome : 0;
    updateState.homeLoans =
      initialHomeLoans.length !== 0 ? initialHomeLoans : 0;
    updateState.unsecuredLiabilities =
      initialUnsecuredLiabilities.length !== 0
        ? initialUnsecuredLiabilities
        : 0;
    updateState.creditCard =
      initialCreditCard.length !== 0 ? initialCreditCard : 0;
    updateState.financialAssets =
      initialFinancialAssets.length !== 0 ? initialFinancialAssets : 0;
    updateState.nonFinancialAssets =
      initialNonFinancialAssets.length !== 0 ? initialNonFinancialAssets : 0;
    updateState.expenses = initialExpenses.length !== 0 ? initialExpenses : 0;

    // Note : parseFloat converts single array to number e.g. [100] -> 100 as well
    // However, when we specify the index such as PROPOSED_CREDIT_CARD_INDEX, we don't need to parseFloat it on calculation.
    // since the type is already specified

    const client1Income =
      parseFloat(updateState.client1Income || 0) +
      parseFloat(updateState.rentalIncome[RENTAL_INCOME_CLIENT1_INDEX] || 0) +
      parseFloat(
        updateState.financialAssets[FINANCIAL_ASSETS_CLIENT1_INDEX] || 0
      );

    const client2Income =
      parseFloat(updateState.client2Income || 0) +
      parseFloat(updateState.rentalIncome[RENTAL_INCOME_CLIENT2_INDEX] || 0) +
      parseFloat(
        updateState.financialAssets[FINANCIAL_ASSETS_CLIENT2_INDEX] || 0
      );

    setOverallIncomeBreakDown({
      client1: {
        income: formatMoney(client1Income),
        tax: formatMoney(computeEstimatedTaxPaid(client1Income)),
        nettIncome: formatMoney(deductedEstimatedTaxPaid(client1Income)),
      },
      client2: {
        income: formatMoney(client2Income),
        tax: formatMoney(computeEstimatedTaxPaid(client2Income)),
        nettIncome: formatMoney(deductedEstimatedTaxPaid(client2Income)),
      },
      totalMonthly: formatMoney(
        deductedEstimatedTaxPaid(client1Income) / 12 +
          deductedEstimatedTaxPaid(client2Income) / 12
      ),
    });

    const alc = {
      creditCardLimit: updateState.creditCard[PROPOSED_CREDIT_CARD_INDEX] || 0,
      client1Income,
      client2Income,
      livingExpense:
        parseFloat(updateState.unsecuredLiabilities || 0) +
        (parseFloat(updateState.homeLoans[MONTHLY_REPAYMENT_INDEX]) || 0) +
        parseFloat(updateState.expenses || 0),
      interestRate: data.interestRate,
      stressBuffer: data.stressBuffer,
      termsInYear: data.termsInYears,
    };
    setALCTotal({
      dti: getDTI({
        totalDebt: updateState.homeLoans[OUTSTANDING_BALANCE_INDEX] || 0,
        totalIncome:
          parseFloat(updateState.client1Income || 0) +
          parseFloat(updateState.client2Income || 0) +
          parseFloat(updateState.rentalIncome || 0),
      }),
      alc: getBorrowingPower(alc),
    });
    setAccordionTotal(updateState);
  };
  const saveDataWithLoading = async (data: any) => {
    setIsLoading(true);
    await saveData(data);
    setTimeout(function () {
      setIsLoading(false);
    }, 500);
  };

  // =================================[ Hooks ]=============================================================

  useEffect(() => {
    getACData(
      rentalIncome,
      allFinancialAssets,
      allNonFinancialAssets,
      incomeBreakdownClient1,
      incomeBreakdownClient2,
      unsecuredLiabilities,
      homeLoans,
      creditCard,
      arrangeFlexExpenseOrder(flexExpenses)
    );
  }, []);

  useEffect(() => {
    ACStates && accordionTotalHandler(ACStates);
  }, [ACStates]);

  const completeVersionControl = async (vId: string) => {
    const idObj = {
      id: vId,
    };
    return _fetch("client/completeVersionControl", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(idObj),
    }).then((res: any) => res.json());
  };

  const completeVersionControlHandler = async () => {
    setIsLoading(true);
    const apiCallComplete = await completeVersionControl(
      String(versionControlId)
    );
    if (apiCallComplete.success) {
      setCompleteSuccess(apiCallComplete.success);
    }
    setTimeout(function () {
      setIsLoading(false);
    }, 500);
  };

  // =================================[ Renders ]============================================================

  const renderComponent = () => {
    if (!_.isNil(ACStates)) {
      return (
        <div className={styles.assessmentCalculatorContainer}>
          {showPortal === true ? (
            <ClientChangesContext.Provider
              value={{
                rentalUpdates,
                rentalAddition,
                financialAssetUpdates,
                financialAssetAddition,
                nonFinancialAssetUpdates,
                nonFinancialAssetAddition,
                income1Updates,
                income1Addition,
                income2Updates,
                income2Addition,
                unsecuredUpdates,
                unsecuredAdditional,
                homeLoansUpdates,
                homeLoansAddition,
                creditUpdates,
                creditAddition,
                flexExpenseUpdates,
                showFlexExpenseTitle,
                ACStates,
                setACStates,
              }}
            >
              <Portal portalRoot={portalDiv}>
                <ClientChangesModal />
              </Portal>
            </ClientChangesContext.Provider>
          ) : (
            <div></div>
          )}
          <div
            onClick={() =>
              saveDataWithLoading({ ...ACStates, versionControlId })
            }
            className={styles.saveButton}
          >
            <Grid>
              <SaveOutlinedIcon
                style={{ fontSize: "20px", width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid style={{ marginTop: "-8px" }}>Save</Grid>
          </div>
          <div className={styles.saveButton2}>
            <div onClick={() => completeVersionControlHandler()}>
              <Grid>
                <DoneIcon
                  style={{
                    fontSize: "20px",
                    width: "100%",
                    height: "100%",
                    marginLeft: "13px",
                  }}
                />
              </Grid>
              <Grid style={{ marginTop: "-8px" }}>
                {completeSuccess || isCompleted === "Completed"
                  ? "Completed"
                  : "Complete"}
              </Grid>
            </div>
          </div>
          <div className={styles.metaDatas}>
            <div className={styles.metaDataL}>
              <div className={styles.container}>
                <div>
                  <EditableText
                    setACStates={setACStates}
                    ACStates={ACStates}
                    title={
                      !_.isNil(ACStates?.title) ? ACStates?.title : "Assessment"
                    }
                  />
                </div>
                <div className={styles.interestRate}>
                  <span style={{ color: "var(--primary-color)" }}>
                    Created On
                  </span>
                  <span>
                    {moment(ACStates?.createdOn).format("DD-MM-YYYY HH:MM")}
                  </span>
                </div>
                <div className={styles.interestRate}>
                  <span style={{ color: "var(--primary-color)" }}>
                    Modified On
                  </span>
                  <span>
                    {moment(ACStates.updatedOn).format("DD-MM-YYYY HH:MM")}
                  </span>
                </div>
              </div>
              <div className={styles.notes}>
                <label>Notes : </label>
                <textarea
                  className={styles.textarea}
                  placeholder="Notes:"
                  onChange={(e: React.ChangeEvent<{ value: any }>) => {
                    const updateState = { ...ACStates } as i_ACStates;
                    updateState.notes = e.target.value;
                    setACStates(updateState);
                  }}
                >
                  {ACStates?.notes}
                </textarea>
              </div>
            </div>
            <div className={styles.metaDataR}>
              <p className={styles.warning}>
                Disclaimer - this is an estimation tool to use for guidance
                only.
              </p>
              <div className={styles.container}>
                <div>
                  <h6 className={styles.textCalculator}>
                    Affordability Lending Calculator: ${ALCTotal.alc} @{" "}
                    <IconButton
                      onClick={() => {
                        setShowIncomeBreakDownDetails(
                          !showIncomeBreakDownDetails
                        );
                      }}
                    >
                      {!showIncomeBreakDownDetails && (
                        <ExpandMoreIcon
                          style={{ fill: "var(--primary-color)" }}
                        ></ExpandMoreIcon>
                      )}
                      {showIncomeBreakDownDetails && (
                        <ExpandLessIcon
                          style={{ fill: "var(--primary-color)" }}
                        ></ExpandLessIcon>
                      )}
                    </IconButton>
                  </h6>
                </div>
                <div className={styles.rateContainer}>
                  <div className={styles.interestRate}>
                    <span>Interest Rate</span>
                    <OutlinedInput
                      margin="dense"
                      defaultValue={ACStates.interestRate}
                      classes={{ root: styles.percentInput }}
                      onChange={(e: React.ChangeEvent<{ value: any }>) => {
                        const updateState = { ...ACStates } as i_ACStates;
                        updateState.interestRate = parseFloat(e.target.value);
                        setACStates(updateState);
                      }}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />
                  </div>
                  <div className={styles.interestRate}>
                    <span>Stress Buffer</span>
                    <OutlinedInput
                      margin="dense"
                      defaultValue={ACStates.stressBuffer}
                      classes={{ root: styles.percentInput }}
                      onChange={(e: React.ChangeEvent<{ value: any }>) => {
                        const updateState = { ...ACStates } as i_ACStates;
                        updateState.stressBuffer = parseFloat(e.target.value);
                        setACStates(updateState);
                      }}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />
                  </div>
                  <div className={styles.interestRate}>
                    <span>Term in Years</span>
                    <OutlinedInput
                      margin="dense"
                      defaultValue={ACStates.termsInYears}
                      classes={{ root: styles.percentInput }}
                      onChange={(e: React.ChangeEvent<{ value: any }>) => {
                        const updateState = { ...ACStates } as i_ACStates;
                        updateState.termsInYears = parseFloat(e.target.value);
                        setACStates(updateState);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  showIncomeBreakDownDetails
                    ? `${styles.smallBox} ${styles.show}`
                    : `${styles.smallBox}`
                }
              >
                <p>
                  Gross income 1 (
                  <small>${overallIncomeBreakDown.client1?.income}</small>) –
                  tax calculated (
                  <small>${overallIncomeBreakDown.client1?.tax}</small>) – net
                  income (
                  <small>${overallIncomeBreakDown.client1?.nettIncome}</small>)
                </p>
                <p>
                  Gross income 2 (
                  <small>{overallIncomeBreakDown.client2?.income}</small>) – tax
                  calculated (
                  <small>${overallIncomeBreakDown.client2?.tax}</small>) – net
                  income (
                  <small>${overallIncomeBreakDown.client2?.nettIncome}</small>)
                </p>
                <p>
                  Total net income (monthly){" "}
                  <small>${overallIncomeBreakDown.totalMonthly}</small>
                </p>
              </div>
              <h6 className={styles.textCalculator}>DTI: {ALCTotal.dti}</h6>
            </div>
          </div>
          <br />
          <br />
          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="primaryIncome"
            headerTitle={["INCOME", props.client1?.fullName].join(" - ")}
            headerTotalTitle={[
              "Total Income",
              !_.isNil(accordionTotal.client1Income)
                ? formatMoney(accordionTotal.client1Income)
                : 0,
            ].join(" $")}
          >
            <div className={styles.incomeBreakdown}>
              <h6>Income Breakdown</h6>
              <div className={styles.table}>
                {ACStates && (
                  <DataTable
                    handleUpdate={handleUpdate}
                    table="client1Income.incomeBreakdown"
                    columns={IncomeColumns}
                    rows={generateIncomeRow(
                      ACStates?.client1Income?.incomeBreakdown
                    )}
                    handleAdd={() =>
                      handleAddRow({
                        type: "client1.incomeBreakdown",
                        ACStates,
                        setACStates,
                        owners: {
                          client1: _.cloneDeep(props?.client1),
                          client2: _.cloneDeep(props?.client2),
                        },
                      })
                    }
                    handleDelete={(i: any) => {
                      const incomeBreakdown =
                        ACStates?.client1Income?.incomeBreakdown.filter(
                          (item: any, index: any) => index !== i
                        );
                      setACStates({
                        ...ACStates,
                        client1Income: {
                          ...ACStates?.client1Income,
                          incomeBreakdown,
                        },
                      });
                    }}
                    funcGetTotal={getTotal}
                  />
                )}
              </div>
            </div>
            <div className={styles.ytdCalculator}>
              <h6>YTD Calculator</h6>
              <div className={styles.table}>
                {ACStates && (
                  <DataTable
                    handleUpdate={handleUpdate}
                    table="client1Income.ytdCalculator"
                    columns={YTDCalcColumns}
                    rows={generateYTDCalcRow(
                      ACStates?.client1Income?.ytdCalculator
                    )}
                    handleAdd={() =>
                      handleAddRow({
                        type: "client1.ytdCalculator",
                        ACStates,
                        setACStates,
                        owners: {
                          client1: _.cloneDeep(props?.client1),
                          client2: _.cloneDeep(props?.client2),
                        },
                      })
                    }
                    handleDelete={(i: any) => {
                      const ytdCalculator =
                        ACStates?.client1Income?.ytdCalculator.filter(
                          (item: any, index: any) => index !== i
                        );
                      setACStates({
                        ...ACStates,
                        client1Income: {
                          ...ACStates?.client1Income,
                          ytdCalculator,
                        },
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </Accordion>
          {ACStates?.client2Income && (
            <Accordion
              uid={props.uid}
              versionControlId={versionControlId}
              category="secondaryIncome"
              headerTitle={["INCOME", props.client2?.fullName].join(" - ")}
              headerTotalTitle={[
                "Total Income",
                !_.isNil(accordionTotal.client2Income)
                  ? formatMoney(accordionTotal.client2Income)
                  : 0,
              ].join(" $")}
            >
              <div className={styles.incomeBreakdown}>
                <h6>Income Breakdown</h6>
                <div className={styles.table}>
                  {ACStates && (
                    <DataTable
                      handleUpdate={handleUpdate}
                      table="client2Income.incomeBreakdown"
                      columns={IncomeColumns}
                      rows={generateIncomeRow(
                        ACStates?.client2Income?.incomeBreakdown
                      )}
                      handleAdd={() =>
                        handleAddRow({
                          type: "client2.incomeBreakdown",
                          ACStates,
                          setACStates,
                          owners: {
                            client1: _.cloneDeep(props?.client1),
                            client2: _.cloneDeep(props?.client2),
                          },
                        })
                      }
                      handleDelete={(i: any) => {
                        const incomeBreakdown =
                          ACStates?.client2Income?.incomeBreakdown.filter(
                            (item: any, index: any) => index !== i
                          );
                        setACStates({
                          ...ACStates,
                          client2Income: {
                            ...ACStates?.client2Income,
                            incomeBreakdown,
                          },
                        });
                      }}
                      overAllTotal={(val: any) => {
                        const FIRST_AND_ONLY_ITEM = 0;
                        const updateState = { ...accordionTotal };
                        updateState.client2Income = val[FIRST_AND_ONLY_ITEM];
                        setAccordionTotal(updateState);
                      }}
                      getOverAllTotal={accordionTotal}
                      funcGetTotal={getTotal}
                    />
                  )}
                </div>
              </div>
              <div className={styles.ytdCalculator}>
                <h6>YTD Calculator</h6>
                <div className={styles.table}>
                  {ACStates && (
                    <DataTable
                      handleUpdate={handleUpdate}
                      table="client2Income.ytdCalculator"
                      columns={YTDCalcColumns}
                      rows={generateYTDCalcRow(
                        ACStates?.client2Income?.ytdCalculator
                      )}
                      handleAdd={() =>
                        handleAddRow({
                          type: "client2.ytdCalculator",
                          ACStates,
                          setACStates,
                          owners: {
                            client1: _.cloneDeep(props?.client1),
                            client2: _.cloneDeep(props?.client2),
                          },
                        })
                      }
                      handleDelete={(i: any) => {
                        const ytdCalculator =
                          ACStates?.client2Income?.ytdCalculator.filter(
                            (item: any, index: any) => index !== i
                          );
                        setACStates({
                          ...ACStates,
                          client2Income: {
                            ...ACStates?.client2Income,
                            ytdCalculator,
                          },
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </Accordion>
          )}

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="deductions"
            headerTitle="Deductions"
            headerTotalTitle={[
              "Total Deductions",
              !_.isNil(accordionTotal.deductions)
                ? formatMoney(accordionTotal.deductions)
                : 0,
            ].join(": $")}
          >
            <div className={styles.table}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="deductions"
                  columns={DeductionsColumns}
                  rows={generateDeductionRow(ACStates?.deductions)}
                  handleAdd={() =>
                    handleAddRow({
                      type: "deductions",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any) => {
                    const deductions = ACStates?.deductions.filter(
                      (item: any, index: any) => index !== i
                    );
                    setACStates({
                      ...ACStates,
                      deductions,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.deductions = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                />
              )}
            </div>
          </Accordion>

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="rentalIncome"
            headerTitle="Rental Income"
            headerTotalTitle={[
              "Total Rent Income",
              !_.isNil(accordionTotal.rentalIncome[RENTAL_INCOME_INDEX])
                ? formatMoney(accordionTotal.rentalIncome[RENTAL_INCOME_INDEX])
                : 0,
            ].join(": $")}
          >
            <div className={styles.table}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="rentalIncome"
                  columns={RentalIncomeColumns}
                  rows={generateRentalIncomeRow(ACStates?.rentalIncome)}
                  handleAdd={() =>
                    handleAddRow({
                      type: "rentalIncome",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any) => {
                    const rentalIncome = ACStates?.rentalIncome.filter(
                      (item: any, index: any) => index !== i
                    );
                    setACStates({
                      ...ACStates,
                      rentalIncome,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.rentalIncome = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                />
              )}
            </div>
          </Accordion>

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="homeLoans"
            headerTitle="Home Loans"
            headerTotalTitle={[
              [
                "Monthly Repayment",
                formatMoney(accordionTotal.homeLoans[MONTHLY_REPAYMENT_INDEX]),
              ].join(": $"),
              [
                "Outstanding Balance",
                formatMoney(
                  accordionTotal.homeLoans[OUTSTANDING_BALANCE_INDEX]
                ),
              ].join(": $"),
            ].join(" | ")}
          >
            <div className={styles.table}>
              {ACStates &&
              ACStates.homeLoans &&
              ACStates.homeLoans.length > 0 ? (
                <MultiDataTable
                  datas={ACStates?.homeLoans}
                  handleUpdate={handleUpdate}
                  table="homeLoans"
                  columns={HomeLoansColumns}
                  rows={(row: any) => generateHomeLoansRow(row)}
                  handleAdd={() =>
                    handleAddRow({
                      type: "homeLoans",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any, row: any) => {
                    const homeLoans = row.filter(
                      (item: any, index: any) => index !== i
                    );
                    setACStates({
                      ...ACStates,
                      homeLoans,
                    });
                  }}
                  // Splice purpose is to arrange total position for homeloans
                  overAllTotal={totalForHomeLoans}
                  getOverAllTotal={accordionTotal.homeLoans}
                  funcGetTotal={getTotal}
                />
              ) : (
                <small>
                  There is no <strong>Home Loans</strong> for this particular
                  user. If you wish to add, go to Borrowing's page.
                </small>
              )}
            </div>
          </Accordion>

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="unsecuredLiabilities"
            headerTitle="Unsecured Liabilities"
            headerTotalTitle={[
              [
                "Monthly Repayment",
                !_.isNil(accordionTotal.unsecuredLiabilities)
                  ? formatMoney(accordionTotal.unsecuredLiabilities)
                  : 0,
              ].join(": $"),
            ].join("")}
          >
            <div className={styles.table}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="unsecuredLiabilities"
                  columns={UnsecuredLiabilitiesColumns}
                  rows={generateUnsecuredLiabilitiesRow(
                    ACStates?.unsecuredLiabilities
                  )}
                  handleAdd={() =>
                    handleAddRow({
                      type: "unsecuredLiabilities",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any) => {
                    const unsecuredLiabilities =
                      ACStates?.unsecuredLiabilities.filter(
                        (item: any, index: any) => index !== i
                      );
                    setACStates({
                      ...ACStates,
                      unsecuredLiabilities,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.unsecuredLiabilities = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                />
              )}
            </div>
          </Accordion>

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="creditCard"
            headerTitle="Credit Card"
            headerTotalTitle={[
              [
                "Credit Card Limit",
                formatMoney(accordionTotal.creditCard[CREDIT_CARD_LIMIT_INDEX]),
              ].join(": $"),
              [
                "Proposed Credit Card",
                formatMoney(
                  accordionTotal.creditCard[PROPOSED_CREDIT_CARD_INDEX]
                ),
              ].join(": $"),
            ].join(" | ")}
          >
            <div className={styles.table}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="creditCard"
                  columns={CreditCardColumns}
                  rows={generateCreditCardRow(ACStates?.creditCard)}
                  handleAdd={() =>
                    handleAddRow({
                      type: "creditCard",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any) => {
                    const creditCard = ACStates?.creditCard.filter(
                      (item: any, index: any) => index !== i
                    );
                    setACStates({
                      ...ACStates,
                      creditCard,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.creditCard = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                />
              )}
            </div>
          </Accordion>

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="financialAssets"
            headerTitle="Financial Assets"
            headerTotalTitle={[
              [
                "Total Financial Assets Income",
                !_.isNil(
                  accordionTotal.financialAssets[FINANCIAL_ASSETS_TOTAL_KEY]
                )
                  ? formatMoney(
                      accordionTotal.financialAssets[FINANCIAL_ASSETS_TOTAL_KEY]
                    )
                  : 0,
              ].join(": $"),
              [
                "Balance Financial Assets",
                !_.isNil(
                  accordionTotal.financialAssets[FINANCIAL_ASSETS_BALANCE_KEY]
                )
                  ? formatMoney(
                      accordionTotal.financialAssets[
                        FINANCIAL_ASSETS_BALANCE_KEY
                      ]
                    )
                  : 0,
              ].join(": $"),
            ].join(" | ")}
          >
            <div className={styles.table}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="financialAssets"
                  columns={FinancialAssetsColumns}
                  rows={generateFinancialAssetsRow(ACStates?.financialAssets)}
                  handleAdd={() =>
                    handleAddRow({
                      type: "financialAssets",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any) => {
                    const financialAssets = ACStates?.financialAssets.filter(
                      (item: any, index: any) => index !== i
                    );
                    setACStates({
                      ...ACStates,
                      financialAssets,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.financialAssets = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                />
              )}
            </div>
          </Accordion>

          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="nonFinancialAssets"
            headerTitle="Non-Financial Assets"
            headerTotalTitle={[
              [
                "Balance Non-Financial Assets",
                !_.isNil(
                  accordionTotal.nonFinancialAssets[
                    NON_FINANCIAL_ASSETS_BALANCE_KEY
                  ]
                )
                  ? formatMoney(
                      accordionTotal.nonFinancialAssets[
                        NON_FINANCIAL_ASSETS_BALANCE_KEY
                      ]
                    )
                  : 0,
              ].join(": $"),
            ].join(" | ")}
          >
            <div className={styles.table}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="nonFinancialAssets"
                  columns={NonFinancialAssetsColumns}
                  rows={generateNonFinancialAssetsRow(
                    ACStates?.nonFinancialAssets
                  )}
                  handleAdd={() =>
                    handleAddRow({
                      type: "nonFinancialAssets",
                      ACStates,
                      setACStates,
                      owners: {
                        client1: _.cloneDeep(props?.client1),
                        client2: _.cloneDeep(props?.client2),
                      },
                    })
                  }
                  handleDelete={(i: any) => {
                    const nonFinancialAssets =
                      ACStates?.nonFinancialAssets.filter(
                        (item: any, index: any) => index !== i
                      );
                    setACStates({
                      ...ACStates,
                      nonFinancialAssets,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.nonFinancialAssets = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                />
              )}
            </div>
          </Accordion>
          <Accordion
            uid={props.uid}
            versionControlId={versionControlId}
            category="expenses"
            headerTitle="Expenses"
            headerTotalTitle={[
              [
                "Monthly Expense",
                !_.isNil(accordionTotal.expenses)
                  ? formatMoney(accordionTotal.expenses)
                  : 0,
              ].join(": $"),
            ].join("")}
          >
            <div className={styles.halftable}>
              {ACStates && (
                <DataTable
                  handleUpdate={handleUpdate}
                  table="expenses"
                  columns={ExpensesColumns}
                  rows={generateExpensesRow(ACStates?.expenses)}
                  handleAdd={() =>
                    handleAddRow({
                      type: "expenses",
                      ACStates,
                      setACStates,
                    })
                  }
                  handleDelete={(i: any) => {
                    const expenses = ACStates?.expenses.filter(
                      (item: any, index: any) => index !== i
                    );
                    setACStates({
                      ...ACStates,
                      expenses,
                    });
                  }}
                  overAllTotal={(val: any) => {
                    const FIRST_AND_ONLY_ITEM = 0;
                    const updateState = { ...accordionTotal };
                    updateState.expenses = val[FIRST_AND_ONLY_ITEM];
                    setAccordionTotal(updateState);
                  }}
                  getOverAllTotal={accordionTotal}
                  funcGetTotal={getTotal}
                ></DataTable>
              )}
            </div>
          </Accordion>
        </div>
      );
    }
  };

  return (
    <>
      {isLoading && (
        <div className={[styles.spinner, "loading-spinner"].join(" ")}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color={"#999"}
            loading={isLoading}
          />
        </div>
      )}
      <Modal
        showModal={showAlertModal}
        onClose={() => {
          setShowAlertModal(false);
        }}
      >
        {alertModalValue}
      </Modal>
      {renderComponent()}
    </>
  );
};

export default AssessmentCalculator;
