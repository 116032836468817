exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".hBElG5DKXbmM1eNh9d_vt {\n  padding: 10px 10px 5px;\n  margin-bottom: 5px;\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important; }\n\n._1AZN82Ww-FtxmgroZAwJfU {\n  margin: 2px 0px 0px 8px; }\n\n.Cn6N_qVAQF-2UyTBskKO1 {\n  color: var(--primary-color) !important;\n  font-size: 20px !important; }\n\n.CsJ76oFuDZdDBFfIJmAVQ {\n  display: flex;\n  justify-content: flex-end; }\n  .CsJ76oFuDZdDBFfIJmAVQ a {\n    margin: 0 10px; }\n    .CsJ76oFuDZdDBFfIJmAVQ a:hover svg {\n      fill: var(--error-color); }\n\n._3tVvff0e7fLnKR_vOnQHqI {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n.eXyucY3jknSxriQzOubxr {\n  color: var(--error-color); }\n\n._1gpEFLf_ppqka6-Es3zTfZ {\n  color: var(--primary-color); }\n\n._3vrbc0ZArkNIoeJL3JZOSB {\n  width: 35em; }\n  ._3vrbc0ZArkNIoeJL3JZOSB.CdfFB2eLJk_f25KGSvO2n {\n    border: 1px solid var(--error-color); }\n  ._3vrbc0ZArkNIoeJL3JZOSB > div {\n    background: #d5d5d5;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2px !important; }\n    ._3vrbc0ZArkNIoeJL3JZOSB > div > button {\n      padding: 0; }\n    ._3vrbc0ZArkNIoeJL3JZOSB > div > input {\n      padding: 2px !important;\n      font-size: 14px; }\n    ._3vrbc0ZArkNIoeJL3JZOSB > div > textarea {\n      padding-top: 4px !important;\n      padding-bottom: 4px !important;\n      padding-left: 2px !important;\n      padding-right: 2px !important;\n      font-size: 14px; }\n\n._1ukL0sycBCG6_zqpKMG-NN {\n  margin: 0 10px 2px 10px !important;\n  width: 15px !important;\n  height: 15px !important; }\n", ""]);
// Exports
exports.locals = {
	"files": "hBElG5DKXbmM1eNh9d_vt",
	"fileName": "_1AZN82Ww-FtxmgroZAwJfU",
	"icons": "Cn6N_qVAQF-2UyTBskKO1",
	"iconHolder": "CsJ76oFuDZdDBFfIJmAVQ",
	"spinner": "_3tVvff0e7fLnKR_vOnQHqI",
	"textRed": "eXyucY3jknSxriQzOubxr",
	"textGreen": "_1gpEFLf_ppqka6-Es3zTfZ",
	"inputWrapper": "_3vrbc0ZArkNIoeJL3JZOSB",
	"duplicateDoc": "CdfFB2eLJk_f25KGSvO2n",
	"checkBox": "_1ukL0sycBCG6_zqpKMG-NN"
};