import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Modal from "../../../components/Modal"
import FileUploaderModal from "../../../components/FileUploaderModal/FileUploaderModal"
import styles from "./../ClientResponse/ClientResponse.module.scss"
import FileUploader from "../../../components/FileUploader/index"
import { FadeLoader } from "react-spinners"
import { fetchLists, editFile, addFileRecords } from "./Documents.controller"
import {
  i_Documents,
  i_clientName,
  i_DocumentPoolFile,
} from "./Documents.interfaces"
import {
  i_Document_2,
  getAllSettingsOther,
  uploadDocument,
  getClientNames,
} from "../../../lib/services"
import _ from "lodash"
import RenderCard from "./RenderCard"
import MultiDownloadButton from "../../../components/MultiDownloadButton/MultiDownloadButton"
const Documents: React.FC<i_Documents> = ({ clientId, client_email }) => {
  // =================================[ State ]====================================

  const [otherDocuments, setOtherDocuments] = useState<i_Document_2[]>([])
  const [droppedFile, setDroppedFile] = useState<File | undefined>()
  const [modalError, setModalError] = useState<string | undefined>()
  const [files, setFiles] = useState<any>([])
  const [showFileUploaderModal, setShowFileUploaderModal] = useState(false)
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)
  const [currentFileEdited, setCurrentFileEdited] = useState("")
  const [modalIsEdit, setModalIsEdit] = useState(false)
  const [fileLabel, setFileLabel] = useState("")
  const [fileType, setFileType] = useState("")
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false)
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("")
  const [checkedDownload, setCheckedDownload] = useState<any[]>([])
  const [clientName, setClientName] = useState<i_clientName | undefined | void>(
    undefined
  )
  const [checkSwitch, setCheckSwitch] = useState<any[]>([]) // true, 'semi', or false
  //rerender count is used to trigger render by force when a state changed is an array or an object. We changed the single state value to make a force rerender for checkSwitch
  const [rerenderCount, setRerenderCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const SEMI = "semi"

  // =================================[ Functions ]==========================

  const refreshRequestedDocuments = () => {
    setIsLoading(true)
    return new Promise(function(resolve, reject) {
      if (clientId) {
        fetchLists({
          uid: clientId,
        }).then(data => {
          let groupedFiles = _.groupBy(data[0]?.files, "documentType")
          const checkall = Object.keys(groupedFiles).map(item => false)
          setCheckSwitch(checkall)
          setFiles(groupedFiles)

          //  update checkedDownload, to remove on the array what's being removed on the fetched array.
          const fileIds = data[0]?.files.map(
            (file: i_DocumentPoolFile) => file._id
          )
          const newCheckedDownload = checkedDownload.filter(
            checkedDownloadSingle => fileIds.includes(checkedDownloadSingle._id)
          )
          setCheckedDownload(newCheckedDownload)

          setTimeout(() => {
            setIsLoading(false)
          }, 500)
          resolve(true)
        })
      }
    })
  }

  const checkDownloadHandler = (file: any) => {
    const newFilesType = files[file.documentType].map((item: any) => {
      if (item._id === file._id) {
        item.checked = !item.checked
      }
      return item
    })
    setFiles((files: any) => {
      files[file.documentType] = newFilesType
      return files
    })
    const allCheckedFiles = Object.keys(files).map(key => {
      return files[key].filter((item: any) => item.checked)
    })
    const conCatChecked = allCheckedFiles.reduce((prev, curr) => {
      const allCurrent = curr.filter((item: any) => item.checked)
      const allPrev = prev.filter((item: any) => item.checked)
      const finalArr = [...allCurrent, ...allPrev]
      return finalArr
    }, [])
    setCheckedDownload(conCatChecked)
  }
  const selectAllHandler = (docType: string, index: number) => {
    const newSwitch = checkSwitch && [...checkSwitch]
    newSwitch[index] = !newSwitch[index] || newSwitch[index] === SEMI
    setCheckSwitch(newSwitch)
    const checkAllType = files[docType].map((item: any) => {
      item.checked = newSwitch[index]
      return item
    })
    setFiles((sFiles: { [x: string]: any }) => {
      sFiles[docType] = checkAllType
      return sFiles
    })
    const allCheckedFiles = Object.keys(files).map(key => {
      return files[key].filter((item: any) => item.checked)
    })
    const conCatChecked = allCheckedFiles.reduce((prev, curr) => {
      const allCurrent = curr.filter((item: any) => item.checked)
      const allPrev = prev.filter((item: any) => item.checked)
      const finalArr = [...allCurrent, ...allPrev]
      return finalArr
    }, [])
    setCheckedDownload(conCatChecked)
  }

  const removeChecked = () =>
    Object.keys(files).map(key => {
      return files[key].map((item: any) => {
        item.checked = false
        return item
      })
    })

  const onFileUploaderModalOpen = (
    fileId: string,
    isEdit: boolean,
    defaultFileType: string,
    fileLabel: string
  ) => () => {
    // unsure why we need curried here...
    setModalIsEdit(isEdit)
    setFileLabel(fileLabel)
    setFileType(defaultFileType)
    setCurrentFileEdited(fileId)
    setShowFileUploaderModal(true)
  }

  const onFileUploaderModalSubmit = (
    dcid: string | null,
    fileName: string,
    label: string,
    fileType: string,
    file?: File | undefined
  ) => {
    if (modalIsEdit) {
      setIsModalSubmitting(true)
      setModalError("")
      editFile({
        uid: clientId,
        fileId: currentFileEdited,
        fileLabel: label,
        documentType: fileType,
      })
        .then(res => {
          refreshRequestedDocuments()
          setIsModalSubmitting(false)
          setShowFileUploaderModal(false)
        })
        .catch(() => {
          setModalError("Unable to connect with AWS S3")
        })
    } else {
      setIsModalSubmitting(true)
      setModalError("")
      uploadDocument(file, fileType, clientId, client_email)
        .then((data: any) => {
          const document = {
            label: label,
            fileName: fileName,
            documentType: fileType,
          }
          addFileRecords({
            requestedDocuments: document,
            clientId: clientId,
            isSingle: true,
          }).then(res => {
            if (!_.isNil(res)) {
              const message = res.message ? res.message : ""
              setSubmitSuccessMessage(message)
              setShowSubmitSuccessModal(true)
              refreshRequestedDocuments()
              setIsModalSubmitting(false)
              setShowFileUploaderModal(false)
            } else {
              setSubmitSuccessMessage(
                "Something is wrong on the uploading process. Please try again."
              )
              setShowSubmitSuccessModal(true)
              setIsModalSubmitting(false)
              setShowFileUploaderModal(false)
            }
          })
        })
        .catch(() => {
          setModalError("Unable to connect with AWS S3")
        })
    }
  }
  const renderSubmitSuccessModal = () => (
    <Modal
      showModal={showSubmitSuccessModal}
      onClose={() => setShowSubmitSuccessModal(false)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3>{submitSuccessMessage}</h3>
        </Grid>
      </Grid>
    </Modal>
  )

  // =================================[ Hooks ]==========================

  useEffect(() => {
    setModalError("")
  }, [fileLabel, fileLabel, files, checkedDownload])

  useEffect(() => {
    refreshRequestedDocuments()
    getAllSettingsOther().then(settings => {
      setOtherDocuments(settings)
    })
    getClientNames("client/getClientNames").then(clientName => {
      setClientName(clientName)
    })
  }, [clientId])

  // All Switch toggle status handler
  useEffect(() => {
    if (files) {
      Object.keys(files).map((key: string, index: number) => {
        const _file = files[key]
        const newCheckSwitch = checkSwitch
        if (_file.filter((f: any) => f.checked).length === _file.length) {
          newCheckSwitch[index] = true
        } else if (_file.filter((f: any) => f.checked).length !== 0) {
          newCheckSwitch[index] = SEMI
        } else {
          newCheckSwitch[index] = false
        }
        setRerenderCount(rerenderCount + 1)
        setCheckSwitch(newCheckSwitch) // needs the rerendercount to change in order to trigger the rerender since this is an array, it needs a single value state to change to force rerendering
        return true
      })
    }
  }, [
    files,
    checkedDownload,
    setCheckSwitch,
    checkSwitch,
    showSubmitSuccessModal,
  ])
  // =================================[ Renders ]==========================
  return (
    <>
      <div className={styles.root2}>
        {checkedDownload.length > 0 && (
          <MultiDownloadButton
            checkedDownload={checkedDownload}
            clientId={clientId}
            clientName={clientName}
            setCheckedDownload={setCheckedDownload}
            removeChecked={removeChecked}
            setShowSubmitSuccessModal={setShowSubmitSuccessModal}
            setSubmitSuccessMessage={setSubmitSuccessMessage}
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            {isLoading ? (
              <div
                className={[
                  styles.spinner,
                  "loading-spinner",
                  styles.noFixed,
                ].join(" ")}
              >
                <FadeLoader
                  height={20}
                  width={5}
                  radius={10}
                  margin={10}
                  color="#999"
                  loading={isLoading}
                />
              </div>
            ) : Object.keys(files)?.length > 0 ? (
              Object.keys(files).map((key, index) => {
                return (
                  <RenderCard
                    key={`file-card-${index}`}
                    index={index}
                    item={files[key]}
                    documentType={key}
                    selectAllHandler={selectAllHandler}
                    checkDownloadHandler={checkDownloadHandler}
                    onFileUploaderModalOpen={onFileUploaderModalOpen}
                    refreshRequestedDocuments={refreshRequestedDocuments}
                    clientId={clientId}
                    checkSwitch={checkSwitch}
                  />
                )
              })
            ) : (
              <p>There is currently no data associated in this section.</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FileUploader
              type="modal-details"
              openModalUpload={onFileUploaderModalOpen("", false, "", "")}
              droppedFile={(file: File) => {
                if (file) {
                  setDroppedFile(file)
                }
              }}
            />
          </Grid>
        </Grid>
      </div>

      <FileUploaderModal
        showModal={showFileUploaderModal}
        defaultFileType={fileType}
        defaultFileLabel={fileLabel}
        onClose={() => setShowFileUploaderModal(false)}
        onSubmit={onFileUploaderModalSubmit}
        droppedFile={droppedFile}
        isEdit={modalIsEdit}
        unsetDroppedFile={(toUnset: Boolean) => {
          if (toUnset) setDroppedFile(undefined)
        }}
        submitting={isModalSubmitting}
        otherDocumentsChoices={otherDocuments}
        error={modalError}
        isFromDocument={true} // all fileuploader modal in documents.tsx should be able to change file type
      />
      {renderSubmitSuccessModal()}
    </>
  )
}

export default Documents
