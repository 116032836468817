import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"

import Paper from "@material-ui/core/Paper"
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye"
import GetAppIcon from "@material-ui/icons/GetApp"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import moment from "moment"
import { FadeLoader } from "react-spinners"
import { deleteFile } from "../../scenes/DocumentCollection/Documents/Documents.controller"
import { DownloadFile, DeleteFile, ViewFile } from "../../lib/services"

import styles from "./FileItem.module.scss"
import RequestItem from "../RequestItem/RequestItem"
interface i_FileItem {
  item: any
  onFileUploaderModalOpen: any
  refreshRequestedDocuments: any
  clientId: any
  isFromDocument: boolean
  folder?: string
  display?: Readonly<{
    download?: boolean
    view?: boolean
    isShowNote?: boolean
    isShowCheckbox?: boolean
    isShowStatus?: boolean
    isShowIconFolder?: boolean
    isShowIconEdit?: boolean
    isShowIconDelete?: boolean
    isShowDateCreated?: boolean
    isShowDateUpdated?: boolean
  }>
  notes?: string
  files?: ReadonlyArray<string>
  isChecked?: boolean
  onCheckedChange?: (checked: boolean) => void
  isReadOnlyNotes?: boolean
  onNotesChange?: any
  checkDownloadHandler?: Function
}
const FileItem: React.FC<i_FileItem> = ({
  item,
  onFileUploaderModalOpen,
  onNotesChange,
  refreshRequestedDocuments,
  clientId,
  isFromDocument,
  folder,
  notes = "",
  files,
  isChecked: isCheckedProps = false,
  display = {
    download: true,
    view: true,
    isShowNote: false,
    isShowCheckbox: false,
    isShowStatus: false,
    isShowIconFolder: true,
    isShowIconEdit: true,
    isShowIconDelete: true,
    isShowDateCreated: true,
    isShowDateUpdated: true,
  },
  onCheckedChange,
  checkDownloadHandler,
  isReadOnlyNotes = false,
}) => {
  const [filesProps, setFilesProps] = useState([])
  const [newItem, setNewItem] = useState(item)
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(isCheckedProps)

  const DeleteFileHandler = async (props: any) => {
    setIsLoading(true)
    if (isFromDocument) {
      await deleteFile({ uid: props.uid, fileId: props.id })
    } else {
      await DeleteFile(props.fileName, clientId)
    }
    await refreshRequestedDocuments()
    setTimeout(function() {
      setIsLoading(false)
    }, 500)
  }

  const onCheckHandler = () => {
    const newValue = !isChecked
    setIsChecked(newValue)
    if (onCheckedChange) {
      onCheckedChange(newValue)
    }
  }

  useEffect(() => {
    if (item) {
      if (isFromDocument) {
        setNewItem({
          files: [item],
        })
      } else {
        setNewItem(item)
      }
    }
  }, [item, setNewItem, isFromDocument])

  useEffect(() => {
    if (isCheckedProps !== undefined) {
      setIsChecked(isCheckedProps)
    }
  }, [isCheckedProps])

  return (
    <>
      {isLoading && (
        <div className={[styles.spinner, "loading-spinner"].join(" ")}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color="#999"
            loading={isLoading}
          />
        </div>
      )}

      {newItem.files &&
        newItem.files.length > 0 &&
        newItem.files.map((file: any) => {
          if (!files) {
            // if there's no files being passed from parent component, the files will inherit the newFiles.files
            files = newItem.files
          }
          return (
            <Paper className={styles.files}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {display &&
                      display.isShowCheckbox &&
                      files &&
                      files.length > 0 && (
                        <input
                          className={styles.checkBox}
                          checked={isChecked}
                          type="checkbox"
                          onClick={onCheckHandler}
                          onChange={(e: any) => {
                            if (checkDownloadHandler !== undefined) {
                              const arrFiles =
                                files &&
                                files.map((i: any) => ({
                                  AWSFileKey: [
                                    "DocumentCollection",
                                    clientId,
                                    i.file_name,
                                  ].join("/"),
                                  checked: !isChecked,
                                  createdAt: i.createdAt,
                                  documentType: "Assets",
                                  fileLabel: i.label,
                                  _id: i._id,
                                }))
                              checkDownloadHandler(arrFiles)
                            }
                          }}
                        />
                      )}
                    {display && !display.isShowIconFolder && (
                      <InsertDriveFileIcon className={styles.icons} />
                    )}{" "}
                    <strong
                      className={styles.fileName}
                      style={{ marginRight: "20px" }}
                    >
                      {file.label || item.fileLabel}
                    </strong>
                    <small
                      hidden={display && !display.isShowDateCreated}
                      className={styles.fileName}
                    >
                      Created At :{" "}
                      {moment
                        .utc(file.createdAt || item.createdAt)
                        .format("YYYY-MM-DD")}
                    </small>
                    <small
                      hidden={display && !display.isShowDateUpdated}
                      className={styles.fileName}
                    >
                      {item.updatedAt &&
                        [
                          " | ",
                          "Modified At : ",
                          moment.utc(item.updatedAt).format("YYYY-MM-DD"),
                        ].join("")}
                      {file.updatedAt &&
                        [
                          " | ",
                          "Modified At : ",
                          moment.utc(file.updatedAt).format("YYYY-MM-DD"),
                        ].join("")}
                    </small>
                  </div>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  {display &&
                    display.isShowStatus &&
                    (files?.length === 0 ? (
                      <span className={styles.textRed}>Not Received</span>
                    ) : (
                      <span className={styles.textGreen}>Received</span>
                    ))}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <div className={styles.iconHolder}>
                    {" "}
                    {display.view && files && files.length > 0 && (
                      <a
                        href="#"
                        onClick={() =>
                          ViewFile(
                            file.file_name || item.AWSFileKey.split("/").pop(),
                            clientId,
                            folder
                          )
                        }
                      >
                        {" "}
                        {/* eslint-disable-line */}{" "}
                        <RemoveRedEyeIcon className={styles.icons} />
                      </a>
                    )}
                    {display.download && files && files?.length > 0 && (
                      <a
                        href="#"
                        onClick={() =>
                          DownloadFile(
                            file.file_name || item.AWSFileKey.split("/").pop(),
                            file.label || item.fileLabel,
                            clientId,
                            folder
                          )
                        }
                      >
                        {" "}
                        {/* eslint-disable-line */}{" "}
                        <GetAppIcon className={styles.icons} />
                      </a>
                    )}
                    <a
                      hidden={display && !display.isShowIconEdit}
                      href="#"
                      onClick={onFileUploaderModalOpen(
                        item._id,
                        true,
                        item.documentType ||
                          (item.dcid && item.dcid.documentType),
                        item.fileLabel || file.label,
                        file._id || null
                      )}
                    >
                      {" "}
                      {/* eslint-disable-line */}{" "}
                      <EditIcon className={styles.icons} />
                    </a>
                    <a
                      hidden={display && !display.isShowIconDelete}
                      href="#"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to proceed delete?"
                          )
                        ) {
                          DeleteFileHandler({
                            fileName: file.file_name,
                            id: item._id,
                            uid: clientId,
                          })
                        }
                      }}
                    >
                      {" "}
                      {/* eslint-disable-line */}{" "}
                      <DeleteIcon className={styles.icons} />
                    </a>
                    {display && display.isShowNote && (
                      <RequestItem
                        isReadOnly={isReadOnlyNotes}
                        notes={notes}
                        onEdit={value => {
                          onNotesChange(item._id, value, item.transactionId)
                        }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          )
        })}
    </>
  )
}
export default FileItem
