import MultiDownloadButton from "../../../components/MultiDownloadButton/MultiDownloadButton"
import React, { ReactElement, useEffect, useState } from "react"
import RequestHistoryGroup from "./RequestHistoryGroup"
import styles from "./RequestHistory.module.scss"
import { _fetch } from "../../../lib/utils"
import _ from "lodash"
import Grid from "@material-ui/core/Grid"
import Modal from "../../../components/Modal"
import { RequestHistoryProps, Document } from "./RequestHistory.interfaces"
import { getClientNames } from "../../../lib/services"
import { i_clientName } from "../Documents/Documents.interfaces"
import { FadeLoader } from "react-spinners"

const StatusComplete = "submitByClient"
const StatusIncomplete = "submitByAdmin"
const StatusCanceled = "cancelled"

const RequestHistory = (props: any): ReactElement => {
  const [state, setState] = useState<any>({})
  const [files, setFiles] = useState<any>([])
  const [checkedDownload, setCheckedDownload] = useState<any[]>([])
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false)
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("")
  const [clientName, setClientName] = useState<i_clientName | undefined | void>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(true)
  const [resetItemComponent, setResetItemComponent] = useState(false)

  const checkDownloadHandler = (fileItem: any) => {
    let newFiles: any = _.clone(checkedDownload)
    // This loops the multi files and decides whether to delete or add into the new set of files
    // which will be passed to the multiple download button.
    fileItem.forEach((element: any, index: number) => {
      if (newFiles.find((i: any) => i._id === element._id)) {
        if (element.checked === false) {
          newFiles = newFiles.filter((i: any) => {
            return i._id !== element._id
          })
        } else {
          // do nothing, don't add, don't delete
        }
      } else {
        newFiles.push(element)
      }
    })
    setCheckedDownload(newFiles)
  }

  const getData = async () => {
    setIsLoading(true)
    const data = await _fetch(
      ["mbworkbench/getRequestHistory", props.clientId || ""].join("/"),
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then(res => res.json())
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    data &&
      setState({
        data: data.map((_data: RequestHistoryProps) => {
          return {
            ..._data,
            requested_documents: _data.requested_documents.map(
              (d: Document) => {
                // NEW : no linked dcid from settings
                if (!d.dcid) {
                  return {
                    ...d,
                    dcid: {
                      documentType: d.documentType,
                      label: d.documentLabel,
                    },
                  }
                } else {
                  // LEGACY : label and type is fetched from settings
                  return d
                }
              }
            ),
          }
        }),
      })
  }

  const removeChecked = () => {
    setResetItemComponent(true)
    setCheckedDownload([])
  }

  const renderSubmitSuccessModal = () => (
    <Modal
      showModal={showSubmitSuccessModal}
      onClose={() => setShowSubmitSuccessModal(false)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3>{submitSuccessMessage}</h3>
        </Grid>
      </Grid>
    </Modal>
  )
  useEffect(() => {
    getData()
    getClientNames("client/getClientNames").then(clientName => {
      setClientName(clientName)
    })
  }, [])

  return (
    <>
      <div className={styles.root}>
        {checkedDownload.length > 0 && (
          <MultiDownloadButton
            checkedDownload={checkedDownload}
            clientId={props.clientId}
            setShowSubmitSuccessModal={setShowSubmitSuccessModal}
            setSubmitSuccessMessage={setSubmitSuccessMessage}
            removeChecked={removeChecked}
            clientName={clientName}
            setCheckedDownload={setCheckedDownload}
          />
        )}
        {isLoading ? (
          <div
            className={[styles.spinner, "loading-spinner", styles.noFixed].join(
              " "
            )}
          >
            <FadeLoader
              height={20}
              width={5}
              radius={10}
              margin={10}
              color="#999"
              loading={isLoading}
            />
          </div>
        ) : state.data?.length > 0 ? (
          <>
            {state.data
              .filter(
                (requestHistory: RequestHistoryProps) =>
                  requestHistory.status === StatusIncomplete
              )
              .map((_data: any, key: any) => (
                <RequestHistoryGroup
                  setResetItemComponent={setResetItemComponent}
                  resetItemComponent={resetItemComponent}
                  checkDownloadHandler={checkDownloadHandler}
                  refresh={getData}
                  clientId={props.clientId}
                  key={key}
                  data={_data}
                />
              ))}
            {state.data
              .filter(
                (requestHistory: RequestHistoryProps) =>
                  requestHistory.status === StatusComplete ||
                  requestHistory.status === StatusCanceled
              )
              .slice(0)
              .reverse()
              .map((_data: any, key: any) => (
                <RequestHistoryGroup
                  setResetItemComponent={setResetItemComponent}
                  resetItemComponent={resetItemComponent}
                  checkDownloadHandler={checkDownloadHandler}
                  refresh={getData}
                  clientId={props.clientId}
                  key={key}
                  data={_data}
                />
              ))}
          </>
        ) : (
          <p>There is currently no data associated in this section.</p>
        )}
      </div>
      {renderSubmitSuccessModal()}
    </>
  )
}

export default RequestHistory
