import React, { ReactElement, useEffect, useState } from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { addFileRecords } from "./../Documents/Documents.controller"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import { FadeLoader } from "react-spinners"

import moment from "moment"
import _ from "lodash"

import adminStyles from "../AdminRequest/AdminRequest.module.scss"
import FileItem from "../../../components/FileItem/FileItem"
import styles from "./RequestHistory.module.scss"
import { _fetch } from "../../../lib/utils"
import { RequestHistoryGroupProps } from "./RequestHistory.interfaces"
import RequestHistoryItem from "./RequestHistoryItem"

const CANCELLED = "cancelled"
const CANCEL_REQUEST = "Cancel Request"
const NOT_COMPLETE = "not-completed"
const OPTIONS = Object.freeze([CANCEL_REQUEST]) // should include Client View option here in the future

const RequestHistoryGroup = ({
  data,
  refresh,
  clientId,
  checkDownloadHandler,
  resetItemComponent,
  setResetItemComponent,
}: RequestHistoryGroupProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const isOpen = Boolean(anchorEl)
  const DATE_FORMAT = "DD/MM/YYYY hh:mma"
  let status = ""

  switch (data.status) {
    case "submitByAdmin":
      status = NOT_COMPLETE
      break
    case "submitByClient":
      status = "completed"
      break
    case CANCELLED:
      status = CANCELLED
      break
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const cancelRequest = () => {
    _fetch(["mbworkbench/setCancelRequest"].join("/"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: data._id }),
    })
      .then((res: any) => res.json())
      .then((res: any) => {
        console.log("Cancel Success", { res })
        refresh()
      })
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    event.stopPropagation()
    switch (OPTIONS[index]) {
      case CANCEL_REQUEST:
        // Add document collection
        setIsLoading(true)
        addFileRecords({
          requestedDocuments: data.requested_documents,
          clientId: clientId,
          isSingle: false,
        })
          .then(res => {
            if (res) {
              cancelRequest()
              setAnchorEl(null)
            }
            setIsLoading(false)
          })
          .catch(error => {
            alert(
              "Something`s wrong! Please contact the website administrator."
            )
            setIsLoading(false)
            console.error(error)
          })
        break
    }
  }

  return (
    <div className={styles.requestHistoryGroupContainer}>
      {isLoading && (
        <div className={[styles.spinner, "loading-spinner"].join(" ")}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color={"#999"}
            loading={isLoading}
          />
        </div>
      )}

      <ExpansionPanel className={styles.requestHistoryGroup}>
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon fontSize="large" style={{fill: 'var(--primary-color)'}} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={styles.accordionHeader}
        >
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <span className={styles.textOrange}>Request Sent</span>
              <br />
              <small>{moment(data.emailedAt).format(DATE_FORMAT)}</small>
            </Grid>
            <Grid item xs={2}>
              {status === CANCELLED && (
                <>
                  <span className={styles.textOrange}>Cancelled</span>
                  <br />
                  <small>{moment(data.cancelledAt).format(DATE_FORMAT)}</small>
                </>
              )}
              {status === "completed" && (
                <>
                  <span className={styles.textOrange}>Request Completed</span>
                  <br />
                  <small>
                    {moment(data.clientSubmittedAt).format(DATE_FORMAT)}
                  </small>
                </>
              )}
              {status === "not-completed" && (
                <>
                  <span className={styles.textRed}>Request Not Completed</span>
                </>
              )}
            </Grid>
            {status === NOT_COMPLETE && (
              <Grid xs={1}>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon
                    fontSize="large"
                    className={styles.textOrange}
                  />
                </IconButton>
                <Menu
                  id={`long-menu-${_.uniqueId()}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={isOpen}
                  onClose={handleClose}
                >
                  {OPTIONS.map((option, index: number) => (
                    <MenuItem
                      className={styles.moreList}
                      key={option}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      <DeleteIcon
                        fontSize="large"
                        className={styles.textOrange}
                      />
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            )}
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.requestHistoryGroupBody}>
          <div className={styles.listContainer}>
            <List className={`${adminStyles.listWrapper} ${styles.p0}`}>
              {setResetItemComponent && (
                <RequestHistoryItem
                  documents={data.requested_documents}
                  transactionId={data._id}
                  status={status}
                  refresh={refresh}
                  clientId={clientId}
                  checkDownloadHandler={checkDownloadHandler}
                  resetComponent={resetItemComponent}
                  setResetItemComponent={setResetItemComponent}
                />
              )}
            </List>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default RequestHistoryGroup
