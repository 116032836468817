import React, { useEffect, useState } from "react";
import { AddDocumentModalProps } from "./../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface";
import Modal from "./../Modal";
import styles from "./AddRequestModal.module.scss";
import { Button, MenuItem, Grid, Select, Input } from "@material-ui/core";

const AddRequestModal: React.FC<AddDocumentModalProps> = (props) => {
  const { documentType, showModal, onSubmit, onClose } = props;
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (!showModal) {
      setSelectedDocumentType("");
      setFileName("");
    }
  }, [showModal]);

  const onDocumentTypeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ): void => {
    setSelectedDocumentType(e.target.value as string);
  };

  const onFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h4>Please Choose</h4>
          <p>Select Type and Name</p>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <label>Type</label>
              <Select
                value={selectedDocumentType}
                onChange={onDocumentTypeChange}
                className={styles.fileName}
              >
                {documentType.map((documentType, i) => (
                  <MenuItem key={`documentType-${i}`} value={documentType}>
                    {documentType}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <label>Name</label>
              <Input
                className={styles.fileName}
                value={fileName}
                onChange={onFileNameChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            color="primary"
            className={styles.primaryButton}
            onClick={() => onSubmit(selectedDocumentType, fileName)}
            style={{ marginLeft: 12 }}
          >
            ADD
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.primaryButton}
            onClick={onClose}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default AddRequestModal;
