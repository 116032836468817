import React, { useState, Dispatch, useEffect } from "react"
import AdminRequest from "./AdminRequest/AdminRequest"
import ClientResponse from "./ClientResponse/ClientResponse"
import RequestHistory from "./RequestHistory/RequestHistory"
import {
  sendRequestDocument,
  i_Document,
  getDocumentRequests,
  i_RequestedDocument,
} from "./../../lib/services"
import NavBar from "./../../components/NavBar/NavBar"
import SuccessModal from "../../components/SuccessModal/SuccessModal"
import Documents from "./Documents/Documents"

interface i_setCurrentPageParam {
  page: string
}
const DocumentCollectionIndex = ({
  setContainerToHaveChanges,
  isAdmin,
  client_id,
  user_email,
  client_email,
  triggerSave,
  triggerDiscard,
  toStateProceed,
  stateToChange,
}: i_DocumentCollectionIndex) => {
  const [toSave, setToSave] = useState(triggerSave)
  const [toDiscard, setToDiscard] = useState(triggerDiscard)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showNewRequestTab, setShowNewRequestTab] = useState(false)
  const [sendStatus, setSendStatus] = useState("savedByAdmin")
  const AdminRequestSubmitHandler = (
    documents: i_Document[],
    email: string,
    status: string
  ) => {
    const requestedClientResponse: i_RequestedDocument[] = documents.map(
      document => ({
        dcid: document._id,
        documentLabel: document.label,
        documentType: document.documentType,
        notes: document.notes,
        status: status,
      })
    )
    return sendRequestDocument(
      requestedClientResponse,
      client_id,
      email,
      status
    ).then(() => {
      setSendStatus(status)
      setShowSuccessModal(true)
    })
  }

  useEffect(() => {
    setToSave(triggerSave)
    setToDiscard(triggerDiscard)
  }, [triggerDiscard, triggerSave])

  useEffect(() => {
    getDocumentRequests(client_id).then(res => {
      if (res.requests && res.requests.length > 0) {
        setShowNewRequestTab(true)
      }
    })
  }, []) // eslint-disable-line

  const [currentPage, setCurrentPage] = useState<i_setCurrentPageParam>({
    page: isAdmin ? "AdminRequest" : "Documents",
  })

  useEffect(() => {
    if (showNewRequestTab === true && !isAdmin)
      setCurrentPage({ page: "ClientResponse" })
  }, [showNewRequestTab])

  const navHandler = (
    setCurrentPage: Dispatch<i_setCurrentPageParam>,
    page: string
  ) => {
    setCurrentPage({ page: page })
  }
  return (
    <>
      <SuccessModal
        status={sendStatus}
        showModal={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
      {isAdmin && isAdmin[0] === "admin" ? (
        <>
          <NavBar
            currentPage={currentPage.page}
            setCurrentPage={(page: string) => navHandler(setCurrentPage, page)}
            pages={[
              { name: "AdminRequest", label: "Request Client", show: true },
              { name: "Documents", label: "Client Documents", show: true },
              { name: "RequestHistory", label: "Request History", show: true },
            ]}
          />
          {currentPage.page === "AdminRequest" && (
            <AdminRequest
              onSubmit={AdminRequestSubmitHandler}
              clientId={client_id}
              user_email={user_email || ""}
              setContainerToHaveChanges={setContainerToHaveChanges}
              triggerSave={toSave || false}
              triggerDiscard={toDiscard || false}
              toStateProceed={toStateProceed}
              stateToChange={stateToChange}
            />
          )}
          {currentPage.page === "Documents" && (
            <Documents clientId={client_id} client_email={client_email} />
          )}
          {currentPage.page === "RequestHistory" && (
            <RequestHistory clientId={client_id} />
          )}
        </>
      ) : (
        <>
          <NavBar
            currentPage={currentPage.page}
            setCurrentPage={(page: string) => navHandler(setCurrentPage, page)}
            pages={[
              { name: "Documents", label: "My Documents", show: true },
              {
                name: "ClientResponse",
                label: "New Requests",
                show: showNewRequestTab,
              },
            ]}
          />
          {currentPage.page === "ClientResponse" ? (
            <ClientResponse
              clientId={client_id}
              client_email={client_email}
              setCurrentPage={(page: string) =>
                navHandler(setCurrentPage, page)
              }
            />
          ) : (
            <Documents clientId={client_id} client_email={client_email} />
          )}
        </>
      )}
    </>
  )
}

interface i_DocumentCollectionIndex {
  setContainerToHaveChanges: Function
  toStateProceed: Function
  stateToChange: boolean
  isAdmin?: boolean | null | string[] | any
  client_id?: string | undefined
  user_email?: string | undefined
  client_email?: string | undefined
  triggerSave?: boolean
  triggerDiscard?: boolean
}
export default DocumentCollectionIndex
