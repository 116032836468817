import React, { useState } from "react";
import styles from "./FileUploader.scss";

const imgDropFile = (
  <svg
    width="36"
    height="31"
    viewBox="0 0 36 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.4167 15.5V27.4583H4.58333V15.5H0.75V27.4583C0.75 29.3375 2.475 30.875 4.58333 30.875H31.4167C33.525 30.875 35.25 29.3375 35.25 27.4583V15.5H31.4167ZM19.9167 16.6446L24.8808 12.2371L27.5833 14.6458L18 23.1875L8.41667 14.6458L11.1192 12.2371L16.0833 16.6446V0.125H19.9167V16.6446Z"
      fill="var(--primary-color)"
    />
  </svg>
);

interface i_FileUploader {
  type: String;
  openModalUpload: Function;
  droppedFile?: Function;
}

const FileUploader = ({
  type,
  openModalUpload,
  droppedFile,
}: i_FileUploader) => {
  const [dragover, setDragover] = useState(false);

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragover(false);
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragover(true);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];
    droppedFile && droppedFile(files);
    openModalUpload();
    setDragover(false);
  };
  const renderDrop = () => (
    <div
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      className={[styles.fileUploaderDrop, dragover && styles.ondropmode].join(
        " "
      )}
    >
      {imgDropFile}
      <br />
      <p>
        Drop files here or <span onClick={() => openModalUpload()}>Browse</span>{" "}
        for File
      </p>
    </div>
  );

  return <div className={styles.fileUploader}>{renderDrop()}</div>;
};
export default FileUploader;
